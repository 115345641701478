import React, { 
  useMemo, 
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import { get, capitalize } from 'lodash';
import moment from 'moment';

import DangerousTooltip from '../../../../components/DangerousTooltip';
import { getUSDateFormat, formatCapital } from '../../../../utils/helpers';
import { OrderFinancingInformation } from '../../../../api/utils';
import { entityTypes } from '../../../../static';

function OrderSummaryTable({ 
  orderData,
  showRemmitance,
}) {
  const debounceId = useRef(null);
  const [financingInformation, setFinancingInformation] = useState({
    remittance: '--',
    financing_fee: '--',
    payment: '--',
    customer_application_fee: '--',
    vendor_application_fee: '--',
    term: '--',
    amount: '--',
    paymentTerm: '--',
    billingFrequency: '--',
  });

  const startAndEndDate = useMemo(() => {
    return `${getUSDateFormat(get(orderData, 'startDate', ''))} - ${getUSDateFormat(get(orderData, 'endDate', ''))}`;
  }, [orderData]);

  const paymentsDisclaimer = useMemo(() => {
    if (financingInformation.payment !== '--' && get(orderData, 'isExpansion', false)) {
      return (<p className="break-all sf-body font-extralight italic text-xs">First and last month <br /> payments may differ</p>);
    }
    return null;
  }, [financingInformation, orderData]);

  const billingDate = useMemo(() => {
    let bDate = '--';
    if (get(orderData, 'billCycleDay', null)) {
      const currentDate = moment();
      const monthPart = currentDate.format('D') > get(orderData, 'billCycleDay', '-') ? Number(currentDate.format('M')) + 1 : currentDate.format('M');
      bDate = `${monthPart}/${get(orderData, 'billCycleDay', '-')}/${currentDate.format('Y')}`;
    }

    return bDate;
  }, [orderData]);

  const remittanceInformation = useMemo(() => {
    if (showRemmitance) {
      return (
        <div className="grid gap-4">
          <div className="bg-white shadow rounded-lg px-8 divide-y divide-vartana-gray-30 gap-y-8">
            <div className="grid auto-rows-auto gap-y-8 py-8">
              <h1 className="sf-card-title text-vartana-black">Remittance</h1>
              <div className="grid grid-cols-2"> {/* row */}
                <div className="grid auto-rows-min gap-y-1">
                  <p className="break-all vartana-p-small-bold text-vartana-gray-50">Remittance amount</p>
                  <p className="break-all sf-body vartana-black">{get(financingInformation, 'remittance', '-')}</p>
                </div>
                <div className="grid auto-rows-min gap-y-1">
                  <p className="break-all vartana-p-small-bold text-vartana-gray-50">Financing fees</p>
                  <p className="break-all sf-body vartana-black">{get(financingInformation, 'financing_fee', '-')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } 
    return null;
  }, [financingInformation, showRemmitance]);

  useEffect(() => {
    async function callApi() {
      const val = await OrderFinancingInformation(orderData.startDate, orderData.endDate, orderData.amount, get(orderData, 'companyNumber', null), orderData.paymentTerm, orderData.billingFrequency);
      setFinancingInformation(val);
    }
    if (orderData.startDate && orderData.endDate && orderData.amount && get(orderData, 'companyNumber', null) && orderData.paymentTerm >= 0 && orderData.billingFrequency) {
      clearTimeout(debounceId.current);
      debounceId.current = setTimeout(() => callApi(), 1000);
    }
  }, [orderData]);


  const entityType = useCallback((type) => {
    if (type) {
      const elem = entityTypes.find((element) => {
        return element.value === type;
      });

      return elem ? elem.label : '--';
    }

    return '--';
  }, []);

  return (
    <>
      <div className="grid gap-4">
        <div className="divide-y divide-sf-vartana-gray-30">
          <div className="grid auto-rows-auto gap-y-8 py-8">
            <div className="grid grid-cols-2 gap-6"> {/* row */}
              <div className="grid auto-rows-min gap-y-1">
                <p className="text-xs text-sf-vartana-gray-60">Company name</p>
                <p className="break-all normal-text sf-body vartana-black">{formatCapital(get(orderData, 'companyName', '--'))}</p>
              </div>
              <div className="grid auto-rows-min gap-y-1">
                <p className="text-xs text-sf-vartana-gray-60">Entity type</p>
                <p className="break-all sf-body normal-text vartana-black">{get(orderData, 'entityType', '-') ? entityType(get(orderData, 'entityType', '-')) : '--'}</p>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-6"> {/* row */}
              <div className="grid auto-rows-min gap-y-1">
                <p className="text-xs text-sf-vartana-gray-60">Legal first name</p>
                <p className="break-all sf-body normal-text vartana-black">{get(orderData, 'firstName', '') ? formatCapital(get(orderData, 'firstName', '')) : '--'}</p>
              </div>
              <div className="grid auto-rows-min gap-y-1">
                <p className="text-xs text-sf-vartana-gray-60">Legal last name</p>
                <p className="break-all sf-body normal-text vartana-black">{get(orderData, 'lastName', '-') ? formatCapital(get(orderData, 'lastName', '')) : '--'}</p>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-6"> {/* row */}
              <div className="grid auto-rows-min gap-y-1">
                <p className="text-xs text-sf-vartana-gray-60">Email</p>
                <p className="break-all sf-body normal-text vartana-black">{get(orderData, 'email', '-') ? get(orderData, 'email', '') : '--'}</p>
              </div>
              <div className="grid auto-rows-min gap-y-1">
                <p className="text-xs text-sf-vartana-gray-60">Phone</p>
                <p className="break-all sf-body normal-text vartana-black">{get(orderData, 'phone', '-') ? get(orderData, 'phone', '') : '--'}</p>

              </div>
            </div>
          </div>
          <div className="grid auto-rows-auto gap-y-8 py-8 gap-6">
            <div className="grid grid-cols-2 gap-x-4"> {/* row */}
              <div className="grid auto-rows-min gap-y-1">
                <p className="text-xs text-sf-vartana-gray-60">Start and end date</p>
                <p className="break-all sf-body normal-text vartana-black">{startAndEndDate}</p>
              </div>
              <div className="grid auto-rows-min gap-y-1">
                <p className="text-xs text-sf-vartana-gray-60">Term</p>
                <p className="break-all sf-body normal-text vartana-black">{get(financingInformation, 'term', '--')}</p>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-x-4"> {/* row */}
              <div className="grid auto-rows-min gap-y-1">
                <p className="text-xs text-sf-vartana-gray-60">Invoice frequency</p>
                <p className="break-all sf-body normal-text vartana-black">{capitalize(get(financingInformation, 'billing_frequency', '--'))}</p>
              </div>
              <div className="grid auto-rows-min gap-y-1">
                <p className="text-xs text-sf-vartana-gray-60">Payment due</p>
                <p className="break-all sf-body normal-text vartana-black">{get(financingInformation, 'payment_term', '--')}</p>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-6"> {/* row */}
              <div className="grid auto-rows-min gap-y-1">
                <div className="flex space-x-1">
                  <p className="break-all text-xs text-sf-vartana-gray-60">Amount</p>
                </div>
                <p className="break-all sf-body normal-text vartana-black">{get(financingInformation, 'amount', '--')}</p>
              </div>
              <div className="grid auto-rows-min gap-y-1">
                <div className="flex space-x-1">
                  <p className="break-all text-xs text-sf-vartana-gray-60">Processing fee</p>
                  <DangerousTooltip
                    element={(
                      <p>
                        A one-time processing fee is <br />
                        added to the 1st payment to <br />
                        cover incurred documentation <br />
                        charges during the customer&apos;s <br />
                        billing setup.
                      </p>
                    )}
                  />
                </div>
                <p className="break-all sf-body normal-text vartana-black">{get(financingInformation, 'customer_application_fee', '--')}</p>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-6"> {/* row */}
              <div className="grid auto-rows-min gap-y-1">
                <p className="break-all text-xs text-sf-vartana-gray-60">Vendor Application Fee</p>
                <p className="break-all sf-body normal-text vartana-black">{get(financingInformation, 'vendor_application_fee', '--')}</p>
              </div>
              <div className="grid auto-rows-min gap-y-1">
                <p className="break-all text-xs text-sf-vartana-gray-60">Payments</p>
                <p className="break-all sf-body normal-text vartana-black">{get(financingInformation, 'payment', '--')}</p>
                {paymentsDisclaimer}
              </div>
            </div>
            <div className="grid auto-rows-min gap-y-1">
              <p className="break-all text-xs text-sf-vartana-gray-60">Billing date</p>
              <p className="break-all sf-body normal-text vartana-black">{billingDate}</p>
            </div>
          </div>
        </div>
      </div>
      {remittanceInformation}
    </>
  );
}

OrderSummaryTable.propTypes = {
  orderData: PropTypes.shape({
    companyName: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    amount: PropTypes.number,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    term: PropTypes.string,
    billingFrequency: PropTypes.string,
    paymentTerm: PropTypes.number,
  }),
  showRemmitance: PropTypes.bool,
};

OrderSummaryTable.defaultProps = {
  orderData: {
    companyName: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    amount: '',
    startDate: '',
    endDate: '',
    term: '',
    billingFrequency: '',
    paymentTerm: '',
  },
  showRemmitance: PropTypes.bool,
};

export default OrderSummaryTable;
