import React from 'react';
import PropTypes from 'prop-types';

function FormFieldMsg({ show, msg, className = '' }) {
  if (show && msg) {
    return (
      <p className={`text-xs ${className}`}>
        {msg}
      </p>
    );
  }
  return null;
}

FormFieldMsg.propTypes = {
  show: PropTypes.bool,
  msg: PropTypes.string,
  className: PropTypes.string,
};

FormFieldMsg.defaultProps = {
  show: false,
  msg: '',
  className: '',
};

export default FormFieldMsg;
