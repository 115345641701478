import React from 'react';
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const contextClass = {
  success: 'bg-white',
  error: 'bg-white',
};

function ToastNotification() {
  return (
    <ToastContainer
      toastClassName={({ type }) => `${contextClass[type]} flex relative justify-between toast-msg-shadow px-1 py-6 cursor-pointer`}
      transition={Zoom}
      position="top-right"
      autoClose={5000}
      newestOnTop
      rtl={2000}
      pauseOnFocusLoss
      pauseOnHover
      icon={false}
      closeButton={null}
    />
  );
}

export default ToastNotification;
