/* eslint-disable react/jsx-props-no-spreading */

import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { useDropzone } from 'react-dropzone';
import { truncate } from 'lodash';

import FormFieldMsg from '../FormFieldMsg';

import uploadIcon from '../../assets/upload.svg';
import fileIcon from '../../assets/file.svg';
import FormLabel from '../FormLabel';

function MultiFileUpload({
  name,
  label,
}) {
  const [field, meta, { setValue }] = useField(name);

  const [files, setFiles] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    setValue(acceptedFiles);
    setFiles(acceptedFiles);
  }, [setFiles, setValue]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'application/pdf',
    maxFiles: 1,
  });

  return (
    <section>
      <FormLabel
        containerClassName="mb-2"
        labelClassName="text-sf-vartana-gray-60"
        name={field.name}
        label={label}
      />
      <div {...getRootProps()} className="border border-dashed border-sf-blue rounded-md cursor-pointer">
        <div className="relative py-8 px-6 text-sf-dark-blue">
          <input
            {...getInputProps()}
            id={field.name}
            name={field.name}
            type="file"
            className="sr-only"
          />
          {
            isDragActive ? (
              <div className="absolute w-full top-0 left-0 h-full text-center z-10 bg-white bg-opacity-90 rounded-md">
                <p className="sf-text-link inline-block transform-gpu translate-y-2/4">Drop the files here ...</p>
              </div>
            ) : null
          }
          {
            files.length ? files.map((file) => (
              <div className="flex justify-between">
                <div className="space-x-2">
                  <img alt="file" src={fileIcon} className="inline-block" />
                  <p className="sf-text-link inline-block">
                    {truncate(file.name, {
                      length: 48,
                      omission: '...',
                    })}
                  </p>
                </div>
                <p className="sf-text-link inline-block">Upload again</p>
              </div>
            )) : (
              <div className="space-x-2">
                <img alt="upload" src={uploadIcon} className="inline-block" />
                <p className="sf-text-link-bold inline-block">
                  Upload or drag and drop PDF file
                </p>
              </div>
            )
          }
        </div>
      </div>
      <FormFieldMsg show={meta.touched} msg={meta.error} className="text-red-400 placeholder-red-400 mt-4" />
    </section>
  );
}


MultiFileUpload.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
};

MultiFileUpload.defaultProps = {
  label: '',
};

export default MultiFileUpload;
