import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { 
  get, startCase, upperCase, capitalize,
} from 'lodash';
import moment from 'moment';

import { useLocation } from 'react-router-dom';

import fileIcon from '../../../../../assets/file.svg';
import Notification from '../../../../../components/Notification';
import { formatCapital } from '../../../../../utils/helpers';

function getUSDateFormat(dateString) {
  if (dateString) {
    return moment(dateString).format('MM/DD/YYYY');
  }
  return '';
}

function OrderDetails({ 
  orderData, 
  orderAgreementPreview,
}) {
  const { state } = useLocation();

  const [showOrderNotification, setShowOrderNotification] = useState(state?.newOrder || false);

  useEffect(() => {
    if (state?.newOrder) {
      setShowOrderNotification(true);
      setTimeout(() => setShowOrderNotification(false), 5000);
    }
  }, [state, setShowOrderNotification]);

  const orderItemsSummary = useMemo(() => {
    const orderItems = get(orderData, 'orderBy.orderItems', []);
    const orderStatus = get(orderData, 'orderBy.status', 'pending');

    const items = [...orderItems, {
      name: 'Shipping Fee',
      formattedAmount: get(orderData, 'orderBy.formattedShippingCharges', ''),
    }, {
      name: 'Application Fee',
      formattedAmount: get(orderData, 'orderBy.formattedCustomerApplicationFee', ''),
    }, {
      name: 'Taxes',
      formattedAmount: get(orderData, 'orderBy.formattedTaxes', ''),
    }];

    if (orderStatus !== 'pending' && orderStatus !== 'canceled' && orderItems) {
      return (
        <div className="py-6 row-span-3">
          <div className="grid grid-cols-2 mt-3">
            <div className="field-label text-sf-vartana-gray-60 p-2">Items</div>
            <div className="field-label text-sf-vartana-gray-60 p-2 text-right">Total due</div>
            {items.map((orderItem, i) => (
              <div className={`p-2 col-span-2 grid grid-cols-2 gap-4 ${i % 2 === 0 ? 'bg-sf-light-blue' : 'bg-white'}`}>
                <div className="sf-body">{orderItem.name}</div>
                <div className="sf-body text-right">{orderItem.formattedAmount}</div>
              </div>
            ))}
            <div className="p-2 col-span-2 grid grid-cols-2 gap-4 bg-white text-sf-vartana-black">
              <div className="sf-body">Total</div>
              <div className="sf-body text-right font-bold">{get(orderData, 'orderBy.formattedSummaryTotal', '')}</div>
            </div>
            <div className="p-2 col-span-2 grid grid-cols-2 gap-4 bg-white text-sf-vartana-black">
              <div className="sf-body"> {capitalize(get(orderData, 'orderBy.billingFrequency', ''))} Payments</div>
              <div className="sf-body text-right font-bold">{get(orderData, 'orderBy.formattedFirstPayment', '')}</div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }, [orderData]);

  const notification = useMemo(() => {
    if (showOrderNotification) {
      const message = 'Order form is submitted successfully! Upon approval, a checkout link will be sent to the customer automatically. \n Visit the Vartana Salesforce widget for updates on the order';
      return (
        <Notification 
          onClick={() => setShowOrderNotification(false)} 
          heading="Order received!" 
          message={message}
        />
      );
    }
    return null;
  }, [showOrderNotification]);

  const agreementPreview = useMemo(() => {
    const orderStatus = get(orderData, 'orderBy.status', 'pending');
    const previewAgreement = get(orderAgreementPreview, 'orderBy.previewAgreement', '');
    
    let href = previewAgreement.url;
    let title = 'Final agreement';
    let fileName = `${get(orderData, 'orderBy.number', 'Order')}-IPA.pdf`;
    if (previewAgreement.preview) {
      href = `data:application/pdf;base64,${previewAgreement.url}`;
      title = 'Preview agreement (Not signed)';
      fileName = `${get(orderData, 'orderBy.number', 'Order')}-IPA-preview.pdf`;
    }
    
    if (orderStatus !== 'pending' && orderStatus !== 'canceled' && previewAgreement.url) {
      return (
        <div className="space-y-1">
          <p className="field-label text-sf-vartana-gray-60">{title}</p>
          <a 
            className="space-x-2 text-sf-blue block" 
            rel="noreferrer" 
            target="_blank" 
            download={fileName}
            href={href}
          >
            <img alt="IPA" src={fileIcon} className="inline-block" />
            <p className="sf-text-link inline-block">{fileName}</p>
          </a>
        </div>
      );
    }
    return null;
  }, [orderAgreementPreview, orderData]);

  return (
    <>
      {notification}
      <div className="grid grid-cols-2 divide-x divide-sf-vartana-gray-30">
        <div className="pr-8 grid grid-rows-2 divide-y">
          <div className="pb-6">
            <h1 className="sf-h1 text-sf-vartana-black">Customer</h1>
            <div className="grid grid-cols-2 gap-y-4 gap-x-10">
              <div className="grid auto-rows-min">
                <p className="break-all field-label text-sf-vartana-gray-60 mt-2">Company name</p>
                <p className="break-all sf-body">{formatCapital(get(orderData, 'orderBy.company.name', ''))}</p>
              </div>
              <div className="grid auto-rows-min">
                <p className="break-all field-label text-sf-vartana-gray-60 mt-2">Entity type</p>
                <p className="break-all sf-body">{upperCase(get(orderData, 'orderBy.company.entityType', ''))}</p>
              </div>
              <div className="grid auto-rows-min">
                <p className="break-all field-label text-sf-vartana-gray-60 mt-2">Legal first name</p>
                <p className="break-all sf-body">{formatCapital(get(orderData, 'orderBy.company.primaryUser.firstName', ''))}</p>
              </div>
              <div className="grid auto-rows-min">
                <p className="break-all field-label text-sf-vartana-gray-60 mt-2">Legal last name</p>
                <p className="break-all sf-body">{formatCapital(get(orderData, 'orderBy.company.primaryUser.lastName', ''))}</p>
              </div>
              <div className="grid auto-rows-min">
                <p className="break-all field-label text-sf-vartana-gray-60 mt-2">Email</p>
                <p className="break-all sf-body">{get(orderData, 'orderBy.company.primaryUser.email', '')}</p>
              </div>
              <div className="grid auto-rows-min">
                <p className="break-all field-label text-sf-vartana-gray-60 mt-2">Phone</p>
                <p className="break-all sf-body">{get(orderData, 'orderBy.company.primaryUser.phone', '')}</p>
              </div>
            </div>
          </div>
          <div className="py-6">
            <h1 className="sf-h1 text-sf-vartana-black">Document</h1>
            <div className="space-y-5 mt-4">
              {get(orderData, 'orderBy.userDocuments', []).filter((document) => document.type !== 'agreement').map((document) => (
                <div className="space-y-1">
                  <p className="field-label text-sf-vartana-gray-60">{startCase(document.type)}</p>
                  <a className="space-x-2 text-sf-blue block" rel="noreferrer" target="_blank" href={document.url}>
                    <img alt="file" src={fileIcon} className="inline-block" />
                    <p className="sf-text-link inline-block">{document.filename}</p>
                  </a>
                </div>
              ))}
              {agreementPreview}
            </div>
          </div>
        </div>
        <div className="pl-8 grid grid-rows-3 divide-y">
          <div className="pb-6">
            <h1 className="sf-h1 text-sf-vartana-black">Order# {get(orderData, 'orderBy.number', '')}</h1>
            <div className="grid grid-cols-3 gap-y-4">
              <div className="grid auto-rows-min col-span-3">
                <p className="break-all field-label text-sf-vartana-gray-60 mt-2">Amount</p>
                <p className="break-all sf-body">{get(orderData, 'orderBy.formattedAmount', '')}</p>
              </div>
              <div className="grid grid-cols-3 gap-x-12 col-span-3">
                <div className="grid auto-rows-min">
                  <p className="break-all field-label text-sf-vartana-gray-60 mt-2">Start date</p>
                  <p className="break-all sf-body">{getUSDateFormat(get(orderData, 'orderBy.startDate', ''))}</p>
                </div>
                <div className="grid auto-rows-min">
                  <p className="break-all field-label text-sf-vartana-gray-60 mt-2">End date</p>
                  <p className="break-all sf-body">{getUSDateFormat(get(orderData, 'orderBy.endDate', ''))}</p>
                </div>
                <div className="grid auto-rows-min">
                  <p className="break-all field-label text-sf-vartana-gray-60 mt-2">Term</p>
                  <p className="break-all sf-body">{get(orderData, 'orderBy.formattedTerm', '')}</p>
                </div>
                <div className="grid auto-rows-min">
                  <p className="break-all field-label text-sf-vartana-gray-60 mt-2">Invoice frequency</p>
                  <p className="break-all sf-body">{capitalize(get(orderData, 'orderBy.billingFrequency', ''))}</p>
                </div>
                <div className="grid auto-rows-min">
                  <p className="break-all field-label text-sf-vartana-gray-60 mt-2">Payment due</p>
                  <p className="break-all sf-body">{get(orderData, 'orderBy.formattedPaymentTerm', '')}</p>
                </div>
              </div>
            </div>
          </div>
          {orderItemsSummary}
        </div>
      </div>
    </>
  );
}

OrderDetails.propTypes = {
  orderData: PropTypes.arrayOf(PropTypes.node).isRequired,
  orderAgreementPreview: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default OrderDetails;
