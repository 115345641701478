export const Api = () => {
  const { google } = window;
  if (google) return google;
  return null;
};

export const placesSuggestions = (inputValue, callback) => {
  const api = Api();
  if (!inputValue || !api) {
    callback([], 503);
    return;
  }
  const autocompleteService = new api.maps.places.AutocompleteService();
  autocompleteService.getPlacePredictions({ 
    input: inputValue,
    strictBounds: false,
    componentRestrictions: { country: 'us' },
    types: ['address'],
  }, (predictions, status) => {
    callback(predictions || [], status);
  });
};

export const placeDetails = (placeId, callback) => {
  const api = Api();
  if (!placeId || !api) {
    callback(null, 503);
    return;
  }
  const placeService = new api.maps.places.PlacesService(new api.maps.Map(document.createElement('div')));
  placeService.getDetails({
    placeId,
    fields: ['name', 'address_components'],
  }, (place, status) => {
    callback(place, status);
  });
};
