/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */

import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

import FormLabel from '../FormLabel';

function FormReadOnlyInput({ 
  name,
  placeholder,
  label,
  value,
  className,
  disabled,
  disableFullstoryRecording,
  tooltip,
}) {
  const [field, , { setValue }] = useField(name);

  const inputValue = useMemo(() => {
    if (typeof value === 'function') {
      return value() || field.value;
    }
    return value || field.value;
  }, [value, field.value]);

  useEffect(() => {
    setValue(inputValue);
  }, [inputValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const defaultClasses = useMemo(() => 'border border-solid rounded', []);
  const disabledClasses = useMemo(() => 'cursor-not-allowed bg-sf-disabled border-none', []);

  const inputProps = {
    ...field,
    name: field.name,
    id: field.name,
    value: inputValue,
    type: 'text',
    placeholder,
    disabled,
    readonly: 'readonly',
    className: `
      py-2
      pl-3
      w-full
      field-text
      focus:outline-none
      focus:ring-0
      focus:border-current
      ${defaultClasses}
      ${disabled ? disabledClasses : ''}
      ${disableFullstoryRecording ? 'fs-mask' : ''}
    `,
  };

  return (
    <div className={`${className}`}>
      <FormLabel containerClassName="mb-2" labelClassName="text-sf-vartana-gray-70" name={field.name} label={label} tooltip={tooltip} />
      <div className="rounded-md shadow-sm">
        <input
          {...inputProps}
        />
      </div>
    </div>
  );
}


FormReadOnlyInput.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  disabled: PropTypes.bool,
  className: PropTypes.string,
  disableFullstoryRecording: PropTypes.bool,
  tooltip: PropTypes.string,
};

FormReadOnlyInput.defaultProps = {
  name: '',
  placeholder: '',
  label: '',
  value: '',
  disabled: false,
  className: '',
  disableFullstoryRecording: false,
  tooltip: '',
};

export default FormReadOnlyInput;
