/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react';

import { 
  useFormik,
  FormikProvider,
} from 'formik';

import * as yup from 'yup';

import {
  useHistory,
  useLocation,
} from 'react-router-dom';

import { omit, get } from 'lodash';
import { toast } from 'react-toastify';
import { useQuery, useMutation, useReactiveVar } from '@apollo/client';
import { useBeforeunload } from 'react-beforeunload';

import FormInput from '../../../../components/FormInput';
import SearchAddress from '../../../../components/SearchAddress';
import Select from '../../../../components/Select';
import AutoLoad from '../../../../components/AutoLoad';
import Button from '../../../../components/Button';
import CustomNotification from '../../../../components/CustomNotification';
import {
  GET_CUSTOMER_BY_NUMBER,
  CREATE_CUSTOMER,
  UPDATE_CUSTOMER,
} from '../../../../graphql/queries/customer';
import { sessionVar, requestModelAmountVar } from '../../../../graphql/cache';

import { states, entityTypes } from '../../../../static';
import { getAllowedTerms, formatCapital } from '../../../../utils/helpers';

const schema = yup.object().shape({
  name: yup.string()
    .min(1, 'Too Short!')
    .max(100, 'Too Long!')
    .required('Company name is required'),
  entityType: yup.string(),
  street: yup.string()
    .required('Address is required'),
  city: yup.string()
    .required('City is required'),
  state: yup.string()
    .required('State is required'),
  zip: yup.string()
    .max(5, 'Too Long!')
    .required('Zip is required'),
  employeeCount: yup.number()
    .positive('Should be greater than 0')
    .required('Employee count is required'),
  annualRevenue: yup.number()
    .positive('Should be greater than 0')
    .max(10000000000000000, 'Cannot exceed more than 10000000000000000')
    .required('Annual revenue is required'),
  maxUnits: yup.number()
    .positive('Should be greater than 0'),
  requestedAmount: yup.number()
    .positive('Should be greater than 0'),
  requestedTerm: yup.number()
    .positive('Should be greater than 0'),
  crmId: yup.string(),
});

function CustomerEdit() {
  const history = useHistory();
  const location = useLocation();
  const useQueryParams = new URLSearchParams(location.search);
  const companyNumber = useQueryParams.get('customerNumber');
  const crmId = useQueryParams.get('crmId') || '';
  const crmAccountId = useQueryParams.get('crmAccountId');
  const crmOpportunityId = useQueryParams.get('crmOpportunityId');
  const crmLastOpportunityStage = useQueryParams.get('crmLastOpportunityStage');
  const crmLastOpportunityProbability = parseFloat(useQueryParams.get('crmLastOpportunityProbability'));
  const crmLastOpportunityCloseDate = useQueryParams.get('crmLastOpportunityCloseDate');
  const crmLastOpportunityAmount = parseFloat(useQueryParams.get('crmLastOpportunityAmount'));
  const [updateCustomer] = useMutation(UPDATE_CUSTOMER);
  const [createCustomer] = useMutation(CREATE_CUSTOMER);

  const [canSafelyExit, setCanSafelyExit] = useState(true);

  const requestModel = useReactiveVar(requestModelAmountVar);
  const session = useReactiveVar(sessionVar);

  const [initialValues, setInitialValues] = useState({
    number: null,
    name: '',
    entityType: '',
    street: '',
    city: '',
    state: '',
    zip: '',
    employeeCount: '',
    annualRevenue: '',
    maxUnits: '',
    requestedAmount: '',
    requestedTerm: '',
    contactNumber: null,
    addressId: null,
    crmId,
    crmAccountId,
    crmOpportunityId,
    crmLastOpportunityStage,
    crmLastOpportunityProbability,
    crmLastOpportunityCloseDate,
    crmLastOpportunityAmount,
  });

  const { 
    data: customerDetails,
    loading: customerLoading, 
  } = useQuery(GET_CUSTOMER_BY_NUMBER, {
    skip: !companyNumber,
    variables: {
      number: companyNumber,
    },
  });

  useEffect(() => {
    if (companyNumber && !customerLoading && customerDetails) {
      setInitialValues((prev) => ({
        ...prev,
        name: formatCapital(get(customerDetails, 'company.name', '')),
        entityType: get(customerDetails, 'company.entityType', ''),
        street: get(customerDetails, 'company.street', ''),
        city: get(customerDetails, 'company.city', ''),
        state: get(customerDetails, 'company.state', ''),
        zip: get(customerDetails, 'company.zip', ''),
        employeeCount: get(customerDetails, 'company.employeeCount', ''),
        annualRevenue: get(customerDetails, 'company.annualRevenue', ''),
        maxUnits: get(customerDetails, 'company.maxUnits', ''),
        requestedAmount: get(customerDetails, 'company.maxUrequestedAmountnits', ''),
        requestedTerm: get(customerDetails, 'company.requestedTerm', ''),
        crmId: get(customerDetails, 'company.crmId', ''),
        contactNumber: get(customerDetails, 'company.primaryUser.number', null),
        addressId: get(customerDetails, 'company.defaultAddressId', null),
        number: get(customerDetails, 'company.number', ''),
      }));
    }
  }, [customerDetails, customerLoading, setInitialValues, companyNumber]);

  const onSubmit = useCallback(async (values, { setErrors }) => {
    try {
      let responseData;
      let valuesToBeSubmitted = [];
      if (requestModel) {
        valuesToBeSubmitted = omit(values, ['maxUnits']);
      } else {
        valuesToBeSubmitted = omit(values, ['requestedAmount', 'requestedTerm']);
      }
      if (companyNumber) {
        responseData = await updateCustomer({
          variables: valuesToBeSubmitted,
        });
      } else {
        responseData = await createCustomer({
          variables: omit(valuesToBeSubmitted, ['number', 'contactNumber', 'addressId']),
        });
      }

      const errors = get(responseData, 'errors', {});

      if (Object.keys(errors).length) {
        setErrors(errors);
      } else {
        setCanSafelyExit(true);
        const newCustomerNumber = get(responseData, 'data.createCustomer.number', companyNumber);
        const newCustomer = newCustomerNumber !== companyNumber;
        const message = newCustomer ? 'New Customer successfully added!' : 'Customer successfully updated!';
        toast.success(({ toastProps }) => {
          return (<CustomNotification type={toastProps.type} message={message} />);
        });
        let queryParams = history.location.search;
        if (newCustomer) queryParams += `&customerNumber=${newCustomerNumber}`;
        history.push(`/forms/help${queryParams}`);
      }
    } catch (e) {
      console.log(e);
    }
  }, [
    requestModel,
    createCustomer,
    companyNumber,
    updateCustomer,
    history,
    setCanSafelyExit,
  ]);

  const formikBag = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit,
  });

  const { 
    isSubmitting, 
    isValid, 
    dirty, 
    setFieldValue,
    handleSubmit,
  } = formikBag;

  const disabled = isSubmitting || !isValid;

  useBeforeunload((event) => {
    if (!canSafelyExit) {
      event.preventDefault();
    }
  });

  const employeeCount = useMemo(() => {
    return (
      <FormInput
        id="employeeCount"
        name="employeeCount"
        type="number"
        label="Number of employees"
        className="flex-1"
        onChange={(e, { setValue }) => {
          const { value } = e.target;
          if (value) {
            setValue(Math.abs(e.target.value));
          } else {
            setValue(e.target.value);
          }
        }}
      />
    );
  }, []);

  const requestedQuantity = useMemo(() => {
    let input = '';
    if (requestModel) {
      input = (
        <>
          {employeeCount}
          <div className="flex flex-row space-x-4">
            <FormInput
              id="requestedAmount"
              name="requestedAmount"
              type="number"
              label="Requested amount"
              prefix="$"
              className="flex-1"
              onChange={(e, { setValue }) => {
                const { value } = e.target;
                if (value) {
                  setValue(Math.abs(e.target.value));
                } else {
                  setValue(e.target.value);
                }
              }}
            />

            <Select
              id="requestedTerm"
              name="requestedTerm"
              label="Requested term"
              options={getAllowedTerms(get(session, 'seller.product.allowedDurations', []))}
              className="flex-1"
              placeholder="Select"
            />
          </div>
        </>
      );
    } else {
      input = (
        <div className="flex flex-row space-x-4">
          {employeeCount}
          <FormInput
            id="maxUnits"
            name="maxUnits"
            type="number"
            label="Max units"
            className="flex-1"
            onChange={(e, { setValue }) => {
              const { value } = e.target;
              if (value) {
                setValue(Math.abs(e.target.value));
              } else {
                setValue(e.target.value);
              }
            }}
          />
        </div>
      );
    }
    return input;
  }, [employeeCount, requestModel, session]);

  return (
    <>
      <h1 className="sf-h1 text-sf-vartana-black">Add new customer</h1>
      <p className="normal-text mt-2">Complete customer&apos;s business info to generate payment plans.</p>
      <FormikProvider value={formikBag}>
        <form 
          className="mt-4"
          onSubmit={handleSubmit}
          onKeyUp={() => {
            setCanSafelyExit(!dirty);
          }}
        >
          <fieldset disabled={isSubmitting}>
            <div className="grid grid-cols-1 divide-y divide-vartana-gray-30">
              <div className="space-y-4 pb-8">
                <div className="flex flex-row space-x-4">
                  <FormInput
                    id="name"
                    name="name"
                    type="text"
                    label="Company name"
                    className="flex-1"
                  />
                </div>

                <div className="flex flex-row space-x-4">
                  <Select
                    id="entityType"
                    name="entityType"
                    label="Entity type"
                    options={entityTypes}
                    className="flex-1"
                    placeholder="Select type"
                  />
                  <FormInput
                    id="annualRevenue"
                    name="annualRevenue"
                    type="number"
                    label="Annual revenue"
                    prefix="$"
                    className="flex-1"
                    onChange={(e, { setValue }) => {
                      const { value } = e.target;
                      if (value) {
                        setValue(Math.abs(e.target.value));
                      } else {
                        setValue(e.target.value);
                      }
                    }}
                  />
                </div>

                {requestedQuantity}

                <div className="flex flex-row">
                  <SearchAddress
                    name="street"
                    label="Street address"
                    containerClassName="flex-1"
                    afterPlaceSelect={(addressComponents) => {
                      if (addressComponents.city) setFieldValue('city', addressComponents.city);
                      if (addressComponents.state?.shortName) setFieldValue('state', addressComponents.state.shortName);
                      if (addressComponents.zip) setFieldValue('zip', addressComponents.zip);
                    }}
                  />
                </div>

                <div className="flex flex-row space-x-4">
                  <FormInput
                    id="city"
                    name="city"
                    type="text"
                    label="City"
                    className="flex-1"
                  />
                  <Select
                    id="state"
                    name="state"
                    label="State"
                    options={states}
                    placeholder="Select state"
                    className="flex-1"
                  />
                  <FormInput
                    id="zip"
                    name="zip"
                    type="text"
                    label="Zip"
                    placeholder="5 digit zipcode"
                    className="flex-1"
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-row justify-center mt-6">
              <AutoLoad loading={isSubmitting}>
                <Button
                  type="submit"
                  disabled={disabled}
                  element={(
                    <p className={`py-4 px-20 sf-button-text text-white ${disabled ? 'bg-sf-vartana-gray-40' : 'bg-sf-blue'} px-24 py-2 rounded`}>Submit</p>
                  )}
                />
              </AutoLoad>
            </div>
          </fieldset>
        </form>
      </FormikProvider>
    </>
  );
}

export default CustomerEdit;
