/* eslint-disable react/prop-types */

import React, { 
  useState,
  useMemo,
  useEffect,
} from 'react';
import {
  Redirect,
  useLocation,
} from 'react-router-dom';
import { 
  Tab, 
  Tabs, 
  TabList, 
  TabPanel,
} from 'react-tabs';

import { useQuery, useReactiveVar } from '@apollo/client';
import { get } from 'lodash';

import { useIntercom } from 'react-use-intercom';
import * as FullStory from '@fullstory/browser';

import { GET_ORDER_BY, GET_ORDER_AGREEMENT_PREVIEW } from '../../../../graphql/queries/order';
import { sessionVar } from '../../../../graphql/cache';

import AutoLoad from '../../../../components/AutoLoad';

import OrderDetails from './OrderDetails';
import TrackStatus from './TrackStatus';


function Summary() {
  const location = useLocation();

  const useQueryParams = new URLSearchParams(location.search);
  const customerNumber = useQueryParams.get('customerNumber');
  const crmOpportunityId = useQueryParams.get('crmOpportunityId');
  const orderNumber = useQueryParams.get('orderNumber');
  const application = useQueryParams.get('application');
  const { update: updateIntercom } = useIntercom();

  const [tabIndex, setTabIndex] = useState(useQueryParams.get('tab') === 'track' ? 1 : 0);

  const { 
    data: orderData,
    loading: orderLoading,
  } = useQuery(GET_ORDER_BY, {
    variables: { 
      number: orderNumber,
      crmOpportunityId,
    },
  });

  const { 
    data: orderAgreementPreview,
  } = useQuery(GET_ORDER_AGREEMENT_PREVIEW, {
    variables: { 
      number: orderNumber,
      crmOpportunityId,
    },
  });

  const orderTabs = useMemo(() => {
    if (!(get(orderData, 'orderBy.currentStepDetails.progress', 0) === 0 && get(orderData, 'orderBy.currentStepDetails.step', 0) === 0) && get(orderData, 'orderBy.status', '') !== 'canceled') {
      return (
        <div className="border-b border-gray-200 mb-8">
          <TabList className="-mb-px flex space-x-8" aria-label="Tabs">
            <Tab className="cursor-pointer border-transparent whitespace-nowrap border-b-2 pb-2 px-2 text-sf-vartana-black sf-text-link text-center">Order detail</Tab>
            <Tab className="cursor-pointer border-transparent whitespace-nowrap border-b-2 pb-2 px-2 text-sf-vartana-black sf-text-link text-center">Track status</Tab>
          </TabList>
        </div>
      );
    }
    return null;
  }, [orderData]);

  const sessionData = useReactiveVar(sessionVar);

  useEffect(() => {
    const user = get(sessionData, 'session.user', null);
    const orderId = get(orderData, 'orderBy.id', 0);
    const companyId = get(orderData, 'orderBy.company.id', 0);
    const orderUrlAdmin = !orderId ? '' : `${process.env.REACT_APP_ADMIN_URL}/admin/orders/${orderId}`;
    const companyUrlAdmin = !companyId ? '' : `${process.env.REACT_APP_ADMIN_URL}/admin/companies/${companyId}`;
    if (user) {
      const fullName = `${user.firstName} ${user.lastName}`;
      FullStory.identify(user.email, {
        customer: fullName,
        displayName: fullName,
        email: user.email,
      });
      updateIntercom({
        name: application === 'hubspot' ? '' : fullName,
        email: user.email,
        created_at: new Date(user.createdAt).valueOf(),
        customAttributes: {
          application: 'widget-app',
          environment: process.env.REACT_APP_NODE_ENV,
          resourceId: user.id,
          resourceType: 'User',
          orderUrlAdmin,
          companyUrlAdmin,
        },
      });
    }
  }, [sessionData, orderData, updateIntercom, application]);

  if (!customerNumber && (!crmOpportunityId || !orderNumber)) return <Redirect to="/" />;

  return (
    <AutoLoad className="mt-6" loading={orderLoading}>
      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)} selectedTabClassName="border-sf-blue whitespace-nowrap border-b-3 sf-text-link-bold">
        {orderTabs}
        <TabPanel>
          <OrderDetails orderData={orderData} orderAgreementPreview={orderAgreementPreview} />
        </TabPanel>
        <TabPanel>
          <TrackStatus 
            companyNumber={customerNumber} 
            crmOpportunityId={crmOpportunityId}
            orderNumber={orderNumber}
          />
        </TabPanel>
      </Tabs>
    </AutoLoad>
  );
}

export default Summary;
