// FYI - https://github.com/JedWatson/react-select/issues/4094

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/prop-types */

import tw from 'twin.macro';
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Select, { components as selectComponents } from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { useField } from 'formik';

import { css } from '@emotion/react';

// import FormFieldError from '../FormFieldErrorV1';
import FormLabel from '../FormLabel';

const CustomInput = (props) => {
  return (
    <selectComponents.Input 
      {...props} 
      className={`
        w-full
        field-text
        border-sf-vartana-gray-40
      `}
      autoComplete="nope" 
    />
  );
};
const CustomPlaceholder = (props) => {
  return (
    <selectComponents.Placeholder 
      {...props}
      className="placeholder-sf-vartana-gray-40 field-text"
    />
  );
};

const CustomIndicatorSeparator = () => null;
const CustomValueContainer = (props) => {
  return (
    <selectComponents.ValueContainer {...props} />
  );
};

const CustomControl = ({
  cx,
  getStyles,
  innerRef,
  children,
  ...props
}) => {
  return (
    <selectComponents.Control
      {...props}
      innerRef={innerRef}
      getStyles={getStyles}
      cx={cx}
      className={cx(
        css(getStyles('control', props)),
        'border-sf-vartana-gray-40',
      )}
    >
      {children}
    </selectComponents.Control>
  );
};

function CustomSelect({
  className,
  isDisabled,
  isLoading,
  isClearable,
  isRtl,
  isSearchable,
  name,
  label,
  options,
  onInputSelect,
  placeholder,
  components,
  asyncCreatable,
  loadOptions,
  onInputChange,
  formatCreateLabel,
  createOptionPosition,
  refCallback,
  onFocus,
  onMenuClose,
  noOptionsMessage,
  styles,
  onMenuOpen,
  onBlur,
  value,
}) {
  const selectRef = useRef();

  const SelectComponent = asyncCreatable ? AsyncCreatableSelect : Select;

  const [field, , { setValue }] = useField(name);

  useEffect(() => {
    if (value) {
      const opt = options.find((option) => option.value === (value));
      setValue(opt?.value);
      onInputSelect(opt);
    }
  }, [value, onInputSelect]); // eslint-disable-line react-hooks/exhaustive-deps

  let customProps = {
    components,
    onInputChange,
    placeholder,
    isDisabled,
    isLoading,
    isClearable,
    isRtl,
    isSearchable,
    options,
    onMenuOpen,
    ref: (r) => {
      refCallback(r);
      selectRef.current = r;
    },
    className: 'tw-w-full',
    classNamePrefix: 'react-custom-select',
    menuPosition: 'fixed',
    styles: {
      ...styles,
      control: (base, state) => {
        return {
          ...base,
          boxShadow: state.isFocused ? 0 : 0,
          borderColor: state.isFocused
            ? '#054BC7'
            : base.borderColor,
          '&:hover': {
            borderColor: state.isFocused
              ? '#054BC7'
              : base.borderColor,
          },
          // ...tw`
          //   border-sf-vartana-gray-40
          //   focus:outline-none 
          //   focus:ring-0 
          //   focus:border-current
          // `,
        };
      },
      input: (base) => ({
        ...base,
        ...tw`focus-within:shadow-none`,
        'input:focus': {
          boxShadow: 'none',
        },
      }),
    },
    value: options ? options.find((option) => option.value === (field.value || value)) : '',
    name: field.name,
    inputId: field.name,
    onChange: (option, action) => {
      setValue(option?.value || '');
      onInputSelect(option, action);
    },
    onFocus,
    onMenuClose,
    noOptionsMessage,
    onBlur: (e) => {
      field.onBlur(e);
      onBlur(e);
    },
  };
  if (SelectComponent === AsyncCreatableSelect) {
    customProps = {
      ...customProps,
      cacheOptions: false,
      loadOptions: (inputValue, callback) => {
        loadOptions(inputValue, callback);
      },
      formatCreateLabel,
      createOptionPosition,
    };
  }

  return (
    <div className={`${className}`}>
      <FormLabel containerClassName="mb-2" labelClassName="text-sf-vartana-gray-60" name={field.name} label={label} />
      <SelectComponent
        {...customProps}
        components={{ 
          Input: CustomInput,
          IndicatorSeparator: CustomIndicatorSeparator,
          ValueContainer: CustomValueContainer,
          Control: CustomControl,
          Placeholder: CustomPlaceholder,
        }}
      />
    </div>
  );
}

CustomSelect.propTypes = {
  label: PropTypes.string,
  withError: PropTypes.bool,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isClearable: PropTypes.bool,
  isRtl: PropTypes.bool,
  isSearchable: PropTypes.bool,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  })),
  onInputSelect: PropTypes.func,
  placeholder: PropTypes.string,
  components: PropTypes.shape({
    DropdownIndicator: PropTypes.func,
    IndicatorSeparator: PropTypes.func,
  }),
  asyncCreatable: PropTypes.bool,
  loadOptions: PropTypes.func,
  onInputChange: PropTypes.func,
  formatCreateLabel: PropTypes.func,
  createOptionPosition: PropTypes.string,
  refCallback: PropTypes.func,
  onFocus: PropTypes.func,
  onMenuClose: PropTypes.func,
  noOptionsMessage: PropTypes.func,
  styles: PropTypes.objectOf(PropTypes.func),
  onMenuOpen: PropTypes.func,
  onBlur: PropTypes.func,
};

CustomSelect.defaultProps = {
  label: '',
  withError: true,
  className: '',
  isDisabled: false,
  isLoading: false,
  isClearable: false,
  isRtl: false,
  isSearchable: true,
  options: [{
    label: 'label',
    value: 'value',
  }],
  onInputSelect: () => {},
  placeholder: 'Select...',
  components: {},
  asyncCreatable: false,
  loadOptions: () => {},
  onInputChange: () => {},
  formatCreateLabel: (value) => {
    return `Create "${value}"`;
  },
  createOptionPosition: 'last',
  refCallback: () => {},
  onFocus: () => {},
  onMenuClose: () => {},
  noOptionsMessage: () => {
    return 'No options';
  },
  styles: {
    control: (styles) => ({ ...styles }),
    option: (styles) => ({ ...styles }),
    input: (styles) => ({ ...styles }),
    placeholder: (styles) => ({ ...styles }),
    singleValue: (styles) => ({ ...styles }),
  },
  onMenuOpen: () => undefined,
  onBlur: () => {},
};

export default CustomSelect;
