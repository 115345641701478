/* eslint-disable react/prop-types */

import React from 'react';
import { useLocation } from 'react-router-dom';
import SendCheckoutLinkForm from '../../page_components/SendCheckoutLinkForm';

function Application() {
  const location = useLocation();

  const useQueryParams = new URLSearchParams(location.search);
  const customerNumber = useQueryParams.get('customerNumber');
  const crmOpportunityId = useQueryParams.get('crmOpportunityId');
  
  return (
    <div className="divide-x divide-sf-vartana-gray-30">
      <div className="px-8">
        <SendCheckoutLinkForm 
          companyNumber={customerNumber} 
          crmOpportunityId={crmOpportunityId}
          isApplication
        />
      </div>
    </div>
  );
}

export default Application;
