import { gql } from '@apollo/client';

export const GET_APPROVED_OFFERS = gql`
  query($number: String!) {
    company(number: $number) {
      id
      number
      minAllowedOrderAmount
      formattedMinAllowedOrderAmount
      creditAppraisal {
        number
        approvedCreditTermsFormatted
        allowedBillingFrequencies
        allowedPaymentTerms
        currentStepDetails
        maxTerm
        applicationRequired
        loanDecision
        updatedAtFormatted
        moreInfo
        pgRequired
        expired
      }
    }
  }
`;

export const GET_COMPANY_BY = gql`
  query ($number: String, $crmAccountId: String) {
    companyBy(number: $number, crmAccountId: $crmAccountId) {
      id
      number
      minAllowedOrderAmount
      formattedMinAllowedOrderAmount
      seller {
        proposalEnabled
      }
      creditAppraisal {
        number
        approvedCreditTermsFormatted
        allowedBillingFrequencies
        allowedPaymentTerms
        currentStepDetails
        maxTerm
        applicationRequired
        loanDecision
        updatedAtFormatted
        moreInfo
        pgRequired
        expired
      }
    }
  }
`;
