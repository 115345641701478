import React from 'react';
import PropTypes from 'prop-types';

import Loader from '../Loader';

function AutoLoad({ 
  children,
  loading,
  className,
  containerClassName,
}) {
  return (
    <>
      { loading ? (
        <Loader 
          isLoading
          className={`${className} w-10 h-10`} 
          containerClassName={containerClassName}
        />
      ) : (
        children
      )}
    </>
  );
}


AutoLoad.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
};

AutoLoad.defaultProps = {
  className: '',
  containerClassName: '',
};

export default AutoLoad; 
