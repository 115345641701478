import React from 'react';
import PropTypes from 'prop-types';

import 'react-step-progress-bar/styles.css';
import { ProgressBar, Step } from 'react-step-progress-bar';

function Wizard({
  step,
  progress,
  isComplete,
  inActive,
}) {
  let className = 'complete';
  let color = '#1B6DFF';

  if (isComplete) {
    className = 'finished';
    color = '#1FC198';
  } else if (inActive) {
    className = 'inactive';
    color = '#FF8A72';
  }

  function getProgress(stepValue, stepProgress) {
    switch (stepValue) {
    case 0:
      return 0;
    case 1:
      return (stepProgress * 50);
    case 2:
      return 50;
    case 3:
      return 100;
    default: 
      return 0;
    }
  }

  return (
    <ProgressBar 
      percent={getProgress(step, progress)} 
      filledBackground={color}
      height={5}
    >
      <Step>
        {({ accomplished }) => (
          <div className={`indexed-step  ${accomplished && getProgress(step, progress) > 0 ? className : ''}`}></div>
        )}
      </Step>
      <Step>
        {({ accomplished }) => (
          <div className={`indexed-step  ${accomplished && getProgress(step, progress) > 0 ? className : ''}`}></div>
        )}
      </Step>
      <Step>
        {({ accomplished }) => (
          <div className={`indexed-step  ${accomplished && getProgress(step, progress) > 0 ? className : ''}`}></div>
        )}
      </Step>
    </ProgressBar>
  );
}

Wizard.propTypes = {
  step: PropTypes.number,
  progress: PropTypes.number,
  isComplete: PropTypes.bool,
  inActive: PropTypes.bool,
};

Wizard.defaultProps = {
  step: 0,
  progress: 0,
  isComplete: false,
  inActive: false,
};

export default Wizard;
