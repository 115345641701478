/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */

import React from 'react';
import PropTypes from 'prop-types';
import crossIcon from '../../assets/cross.svg';

function FormLabel({ 
  heading, 
  message,
  onClick,
}) {
  return (
    <div className="rounded bg-sf-light-green py-4 px-6 my-8 w-full">
      <div className="flex justify-between">
        <h2 className="sf-h2 text-sf-dark-green inline-block">{heading}</h2>
        <button className="cursor-pointer" onClick={onClick}>
          <img alt="close" src={crossIcon} />
        </button>
      </div>
      <p className="normal-text whitespace-pre-line text-sf-dark-green mt-2"> {message}</p>
    </div>
  );
}

FormLabel.propTypes = {
  heading: PropTypes.string.isRequired,
  message: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

FormLabel.defaultProps = {
  message: '',
};

export default FormLabel; 
