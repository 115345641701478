import React, {
  useMemo,
  useState,
  useContext,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useWindowSize } from 'react-use';
import Confetti from 'react-confetti';
import PropTypes from 'prop-types';

import Wizard from './Wizard';
import Button from '../../../../components/Button';
import { ConfettiContext } from '../../../../utils/contexts';
import { CREATE_CREDIT_APPRAISAL } from '../../../../graphql/queries/creditAppraisal';

const CTAButton = ({
  bgClassName, textClassName, btnText, onClick,
}) => (
  <div className="flex flex-row justify-center items-center">
    <Button
      className={bgClassName}
      type="submit"
      element={(
        <p className={textClassName}>{btnText}</p>
      )}
      onClick={onClick}
    />
  </div>
);

CTAButton.propTypes = {
  bgClassName: PropTypes.string.isRequired,
  textClassName: PropTypes.string.isRequired,
  btnText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

function Updates({
  title,
  message,
  timestamp,
  showResend,
  showCreateCustomer,
  showSendApplication,
  isComplete,
  showWizard,
  icon,
  progress,
  step,
  companyNumber,
  crmOpportunityId,
  subtractHeight,
  inActive,
  showRenewTermsBtn,
}) {
  const { width, height } = useWindowSize();
  const location = useLocation();
  const confettiContext = useContext(ConfettiContext);
  const [createAppraisal] = useMutation(CREATE_CREDIT_APPRAISAL);
  const [disable, setDisable] = useState(false);

  const [showConfetti, setShowConfetti] = useState(() => {
    if (isComplete && confettiContext?.confettiCounter < 1) {
      confettiContext.setConfettiCounter(1);
      return true;
    }
    return false;
  });

  const confetti = useMemo(() => {
    if (showConfetti) {
      setTimeout(() => setShowConfetti(false), 5000);
      return (
        <Confetti
          width={width}
          height={height - subtractHeight}
        />
      );
    }
    return null;
  }, [width, height, subtractHeight, showConfetti]);

  const wizard = useMemo(() => {
    if (showWizard) {
      return (
        <div className="w-2/4 justify-self-center">
          <Wizard progress={progress} step={step} isComplete={isComplete} inActive={inActive} />
        </div>
      );
    }
    return null;
  }, [showWizard, progress, step, isComplete, inActive]);

  const statusIcon = useMemo(() => {
    if (icon) {
      return (
        <div className="my-2 justify-self-center">
          <img className="h-12" src={icon} alt={icon} />
        </div>
      );
    }
    return null;
  }, [icon]);

  const resendButton = useMemo(() => {
    if (showResend) {
      return (
        <div className="flex flex-row justify-center items-center">
          <Button
            className="bg-sf-blue rounded px-16 py-2"
            type="submit"
            disabled={false}
            element={(
              <p className="sf-button-text text-white">Resend checkout link</p>
            )}
            onClick={() => window.open(`/forms/summary?customerNumber=${companyNumber}&crmOpportunityId=${crmOpportunityId}&tab=track`).focus()}
          />
        </div>
      );
    }
    return null;
  }, [showResend, companyNumber, crmOpportunityId]);

  const sendApplication = useMemo(() => {
    if (showSendApplication) {
      return (
        <div className="flex flex-row justify-center items-center">
          <Button
            className="bg-sf-blue rounded px-16 py-2"
            type="submit"
            disabled={false}
            element={(
              <p className="sf-button-text text-white">Send application</p>
            )}
            onClick={() => window.open(`/forms/application?customerNumber=${companyNumber}&crmOpportunityId=${crmOpportunityId}`).focus()}
          />
        </div>
      );
    }
    return null;
  }, [showSendApplication, crmOpportunityId, companyNumber]);

  const renewTerms = useMemo(() => {
    if (showRenewTermsBtn) {
      const refreshAppraisal = async () => {
        try {
          setDisable(true);
          await createAppraisal({ variables: { companyNumber } });
          window.location.reload();
        } catch (e) {
          console.log(e);
        }
      };

      return (
        <div className="flex-1 flex flex-row justify-center mt-6">
          <button
            type="submit"
            onClick={refreshAppraisal}
            disabled={disable}
            className={`py-4 w-full max-w-sm rounded-md  ${disable ? 'bg-sf-vartana-gray-40' : 'bg-sf-vartana-blue-60'}`}
          >
            <p className="sf-btn-text text-white">Generate new terms</p>
          </button>
        </div>
      );
    }
    return null;
  }, [showRenewTermsBtn, createAppraisal, companyNumber, disable]);

  return (
    <>
      {confetti}
      <div className="grid grid-flow-row auto-rows-max gap-4">
        <div className="grid grid-flow-row auto-rows-max gap-8">
          {wizard}
        </div>
        {statusIcon}
        <p className="text-center sf-h2 text-sf-vartana-black">{title}</p>
        <div className="grid grid-flow-row auto-rows-max gap-2">
          <p className="text-center whitespace-pre-line text-sf-vartana-black sf-p-small">{message}</p>
          <p className="text-center text-sf-vartana-gray-50 sf-p-small">{timestamp}</p>
        </div>
        {resendButton}
        {sendApplication}
        {renewTerms}
        {showCreateCustomer && (
          <CTAButton
            bgClassName="bg-sf-blue rounded px-16 py-2"
            textClassName="sf-button-text text-white"
            btnText="Create Customer"
            onClick={() => window.open(`/forms/customer${location.search}`).focus()}
          />
        )}
      </div>
    </>
  );
}

Updates.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  timestamp: PropTypes.string,
  showResend: PropTypes.bool,
  showSendApplication: PropTypes.bool,
  showCreateCustomer: PropTypes.bool,
  isComplete: PropTypes.bool,
  showWizard: PropTypes.bool,
  icon: PropTypes.string,
  progress: PropTypes.number,
  step: PropTypes.number,
  companyNumber: PropTypes.string,
  crmOpportunityId: PropTypes.string,
  subtractHeight: PropTypes.number,
  inActive: PropTypes.bool,
  showRenewTermsBtn: PropTypes.bool,
};

Updates.defaultProps = {
  title: '',
  message: '',
  timestamp: '',
  showResend: false,
  showSendApplication: false,
  showCreateCustomer: false,
  isComplete: false,
  showWizard: true,
  icon: '',
  progress: 0,
  step: 0,
  companyNumber: '',
  crmOpportunityId: '',
  subtractHeight: 0,
  inActive: false,
  showRenewTermsBtn: false,
};

export default Updates;
