import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

export default function Error({ errorTitle, errorMessage }) {
  const { state } = useLocation();
  const errorTitleText = state?.errorTitle || errorTitle;
  const errorMessageText = state?.errorMsg || errorMessage;

  return (
    <div className="min-h-screen flex flex-row justify-center items-center">
      <div className="bg-white flex flex-col sm:max-w-50vw lg:max-w-35vw border-solid rounded border border-grey-1 p-7">
        <div className="sm:justify-start sm:justify-left flex flex-row  flex-1 pt-2">
          <div className="flex-1 items-center justify-center">
            <h5 className="error-title font-bold text-center sm:text-left">
              { errorTitleText }
            </h5>
          </div>
        </div>
        <div className="error-msg mt-2">
          { errorMessageText }
        </div>
      </div>
    </div>
  );
}

Error.propTypes = {
  errorTitle: PropTypes.string,
  errorMessage: PropTypes.string,
};

Error.defaultProps = {
  errorTitle: 'Error',
  errorMessage: 'Please try again later',
};
