/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

import FormLabel from '../FormLabel';

function FormFieldMsg({ show, msg, className = '' }) {
  if (show) {
    return (
      <p className={`text-xs ${className}`}>
        {msg}
      </p>
    );
  }
  return null;
}
function FormInput({ 
  name,
  placeholder,
  label,
  value,
  className,
  disabled,
  disableFullstoryRecording,
  toolTipElement,
  warningIf,
}) {
  const [field, meta] = useField(name);
  const warningMsg = warningIf(field.value);

  const defaultClasses = useMemo(() => 'border border-solid rounded', []);
  const disabledClasses = useMemo(() => 'cursor-not-allowed bg-sf-disabled border-none', []);

  const styleClasses = useMemo(() => ({
    default: {
      borders: 'border-sf-vartana-gray-40',
      text: 'placeholder-sf-vartana-gray-40',
      label: 'text-sf-vartana-gray-60',
    },
    error: {
      borders: 'border-red-400 focus:outline-none focus:ring-0 focus:border-current',
      text: 'text-red-400 placeholder-red-400',
      label: 'text-red-400',
    },
    warning: {
      borders: 'border-sf-vartana-dark-mustard focus:outline-none focus:ring-0 focus:border-current',
      text: 'text-sf-vartana-dark-mustard placeholder-sf-vartana-dark-mustard',
      label: 'text-sf-vartana-dark-mustard',
    },
  }), []);

  const formStateClasses = useMemo(() => {
    let classes = styleClasses.default;
    if (meta.touched && meta.error) {
      classes = styleClasses.error;
    } else if (warningMsg) {
      classes = styleClasses.warning;
    }
    return classes;
  }, [styleClasses, meta, warningMsg]);


  const inputProps = {
    ...field,
    name: field.name,
    id: field.name,
    value: value || field.value,
    placeholder,
    disabled,
    autoComplete: 'off',
    className: `
      py-2
      w-full
      field-text
      ${defaultClasses}
      ${disabled ? disabledClasses : ''}
      ${disableFullstoryRecording ? 'fs-mask' : ''}
      ${formStateClasses.borders}
      ${formStateClasses.text}
    `,
  };
  return (
    <div className={`${className}`}>
      <FormLabel containerClassName="mb-2" labelClassName={formStateClasses.label} name={field.name} label={label} toolTipElement={toolTipElement} />
      <textarea {...inputProps} />
      <FormFieldMsg show={meta.touched} msg={meta.error || warningMsg} className={`${formStateClasses.text} mt-4`} />
    </div>
  );
}


FormInput.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  disableFullstoryRecording: PropTypes.bool,
  toolTipElement: PropTypes.elementType,
  warningIf: PropTypes.func,
};

FormInput.defaultProps = {
  name: '',
  placeholder: '',
  label: '',
  value: '',
  disabled: false,
  className: '',
  disableFullstoryRecording: false,
  toolTipElement: null,
  warningIf: () => '',
};

export default FormInput; 
