import { gql } from '@apollo/client';

export const CREATE_ORDER_CRM = gql`
  mutation(
    $companyNumber: String!, 
    $firstName: String!,
    $lastName: String!,
    $email: String!,
    $phone: String!,
    $crmOpportunityId: String,
    $crmOpportunityStage: String,
    $crmOpportunityProbability: Float,
    $crmOpportunityCloseDate: ISO8601Date,
    $startDate: ISO8601Date!
    $endDate: ISO8601Date!
    $amount: Float!
    $isExpansion: Boolean!
    $billingFrequency: String
    $paymentTerm: Int
  ) {
    createOrderCrm(
      companyNumber: $companyNumber
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      crmOpportunityId: $crmOpportunityId
      crmOpportunityStage: $crmOpportunityStage
      crmOpportunityCloseDate: $crmOpportunityCloseDate
      crmOpportunityProbability: $crmOpportunityProbability
      startDate: $startDate
      endDate: $endDate
      amount: $amount
      isExpansion: $isExpansion
      billingFrequency: $billingFrequency
      paymentTerm: $paymentTerm
    ) {
      id
      number
    }
  }
`;

export const GET_ORDER_BY = gql`
  query(
    $crmOpportunityId: String,
    $number: String
  ) {
    orderBy(
      crmOpportunityId: $crmOpportunityId
      number: $number
    ) {
      id
      number
      amount
      formattedAmount
      startDate
      endDate
      term
      currentStepDetails
      formattedFirstPayment
      formattedCustomerApplicationFee
      formattedVendorApplicationFee
      formattedShippingCharges
      formattedTaxes
      formattedTerm
      formattedSummaryTotal
      status
      emailHistory
      updatedAtFormatted
      formattedPaymentTerm
      billingFrequency
      orderItems {
        name
        description
        formattedUnitPrice
        amount
        formattedAmount
      }
      company {
        id
        name
        entityType
        primaryUser {
          firstName
          lastName
          email
          phone
        }
      }
      userDocuments {
        type
        url
        filename
      }
    }
  }
`;

export const GET_ORDER_AGREEMENT_PREVIEW = gql`
  query(
    $crmOpportunityId: String,
    $number: String
  ) {
    orderBy(
      crmOpportunityId: $crmOpportunityId
      number: $number
    ) {
      previewAgreement
    }
  }
`;

export const SEND_CHECKOUT = gql`
  mutation(
    $companyNumber: String, 
    $crmOpportunityId: String,
    $number: String,
    $firstName: String!,
    $lastName: String!,
    $email: String!,
    $phone: String!,
    $message: String!,
  ) {
    sendCheckout(
      companyNumber: $companyNumber
      crmOpportunityId: $crmOpportunityId
      number: $number
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      message: $message
    ) {
      id
    }
  }
`;
