import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import vartanaLogo from '../../../../assets/vartana-logo.svg';
import helpIcon from '../../../../assets/help.svg';
import detailIcon from '../../../../assets/detail.svg';
import trackIcon from '../../../../assets/track.svg';

function FooterButton({
  redirectUri,
  icon,
  iconAlt,
}) {
  return (
    <button 
      className="block ml-auto cursor-pointer" 
      onClick={() => window.open(redirectUri).focus()}
    > 
      <img src={icon} alt={iconAlt} className="h-12" /> 
    </button>
  );
}

FooterButton.propTypes = {
  redirectUri: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
  iconAlt: PropTypes.string.isRequired,
};

function Footer({
  showTrack,
  showDetail,
  showHelp,
  companyNumber,
  crmOpportunityId,
  forwardedRef,
  redirectUri,
}) {
  const footerButtons = useMemo(() => {
    const buttons = [];
    if (showTrack) {
      buttons.push(
        <FooterButton
          key="showTrack"
          companyNumber={companyNumber}
          crmOpportunityId={crmOpportunityId}
          icon={trackIcon}
          iconAlt="track"
          redirectUri={`/forms/summary?customerNumber=${companyNumber}&crmOpportunityId=${crmOpportunityId}&tab=track`}
        />,
      );
    }
    if (showDetail) {
      buttons.push(
        <FooterButton
          key="showDetail"
          companyNumber={companyNumber}
          crmOpportunityId={crmOpportunityId}
          icon={detailIcon}
          iconAlt="detail"
          redirectUri={`/forms/summary?customerNumber=${companyNumber}&crmOpportunityId=${crmOpportunityId}&tab=details`}
        />,
      );
    }
    if (showHelp) {
      buttons.push(
        <FooterButton
          key="showHelp"
          companyNumber={companyNumber}
          crmOpportunityId={crmOpportunityId}
          icon={helpIcon}
          iconAlt="help"
          redirectUri={redirectUri}
        />,
      );
    }
    return buttons;
  }, [showTrack, showDetail, showHelp, companyNumber, crmOpportunityId, redirectUri]);

  return (
    <div className="bg-sf-light-blue w-full bottom-0 fixed" ref={forwardedRef}>
      <div className="grid grid-cols-2 gap-x-9 px-10 py-2">
        <div className="relative">
          <img className="absolute top-0 bottom-0 m-auto w-28" src={vartanaLogo} alt="Powered by Vartana" />
        </div>
        <div className="grid grid-flow-col auto-cols-auto gap-x-6">
          {footerButtons}
        </div>
      </div>
    </div>
  );
}        

Footer.propTypes = {
  showTrack: PropTypes.bool,
  showDetail: PropTypes.bool,
  showHelp: PropTypes.bool,
  companyNumber: PropTypes.string,
  crmOpportunityId: PropTypes.string,
  redirectUri: PropTypes.string,
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.elementType }),
  ]),
};

Footer.defaultProps = {
  showTrack: false,
  showDetail: false,
  showHelp: true,
  companyNumber: '',
  crmOpportunityId: '',
  forwardedRef: null,
  redirectUri: '',
};

export default Footer;
