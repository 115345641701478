import React, {
  useMemo,
} from 'react';

import {
  useLocation,
} from 'react-router-dom';

import PropTypes from 'prop-types';

import ApprovedPaymentPlans from '../../page_components/ApprovedPaymentPlans';
import Button from '../../../../components/Button';
import PgRequired from '../../page_components/PgRequired';

function Offers({
  offers,
  formattedMinAllowedOrderAmount,
  pgRequired,
  companyNumber,
  proposalAllowed,
}) {
  const location = useLocation();
  const queryParams = `${location.search}&customerNumber=${companyNumber}`;

  const pgMessage = useMemo(() => (pgRequired ? <PgRequired /> : null), [pgRequired]);

  return (
    <div className="p-8">
      <div className="space-y-3">
        <div className="space-y-3">
          <h1 className="sf-h1 text-sf-vartana-black text-center">Approved payment plans</h1>
          <ApprovedPaymentPlans
            offers={offers}
            formattedMinAllowedOrderAmount={formattedMinAllowedOrderAmount}
          />
        </div>
        <div className="flex flex-row justify-center items-center px-5">
          <Button
            className="bg-sf-blue w-full rounded py-2"
            label="Checkout" 
            type="submit"
            disabled={false}
            element={<p className="sf-button-text text-white">Checkout</p>}
            onClick={() => window.open(`/forms/order${queryParams}`).focus()}
          />
        </div>
        {
          proposalAllowed ? (
            <div className="flex flex-row justify-center items-center px-5">
              <Button
                className="border-2 border-sf-blue w-full rounded py-2"
                label="Generate proposal"
                type="submit"
                disabled={false}
                element={<p className="sf-button-text text-sf-blue">Create proposal</p>}
                onClick={() => window.open(`/forms/proposal${queryParams}`).focus()}
              />
            </div>
          ) : null
        }
        <div className="flex flex-row justify-center items-center px-5">
          <Button
            className="border-2 border-sf-blue w-full rounded py-2"
            label="Request to modify terms"
            type="submit"
            disabled={false}
            element={<p className="sf-button-text text-sf-blue">Request to modify terms</p>}
            onClick={() => window.open(`/forms/request-higher-limit${queryParams}`).focus()}
          />
        </div>
      </div>
      <div className="mt-4 mb-8">
        {pgMessage}
      </div>
    </div>
  );
}

Offers.propTypes = {
  offers: PropTypes.arrayOf(PropTypes.node),
  formattedMinAllowedOrderAmount: PropTypes.string,
  pgRequired: PropTypes.bool,
  companyNumber: PropTypes.string.isRequired,
  proposalAllowed: PropTypes.bool,
};

Offers.defaultProps = {
  offers: [],
  formattedMinAllowedOrderAmount: '',
  pgRequired: false,
  proposalAllowed: false,
};

export default Offers;
