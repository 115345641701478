import React, { 
  useMemo,
} from 'react';
import PropTypes from 'prop-types';

import PgRequired from '../PgRequired';
import { termKeys } from '../../../../static';

function ApprovedPaymentPlans({
  offers,
  pgRequired,
  formattedMinAllowedOrderAmount,
}) {
  const pgMessage = useMemo(() => (pgRequired ? <PgRequired /> : null), [pgRequired]);
  const minTerm = useMemo(() => {
    return (
      <div className="flex flex-row justify-center">
        <div className="flex items-center">
          <p className="text-sf-vartana-gray-60 sf-body text-center">
            {formattedMinAllowedOrderAmount} minimum on all terms
          </p>
        </div>
      </div>
    );
  }, [formattedMinAllowedOrderAmount]);

  return (
    <>
      <div className="grid grid-cols-3">
        <div className="p-2 col-span-3 grid grid-cols-3 gap-4">
          {
            ['Term', 'Max', 'Available'].map((term) => {
              return (
                <div key={term} className="field-label text-sf-vartana-gray-60">{term}</div>
              );
            })
          }
        </div>
        {
          offers.map((term, index) => {
            return (
              <div
                key={termKeys[index]}
                className={`
                  px-4 py-2 col-span-3 grid grid-cols-3 gap-4 border-sf-vartana-gray-30
                  ${index % 2 === 0 ? 'bg-sf-disabled bg-opacity-50' : 'bg-white'} 
                  ${index === 0 ? 'border-t' : ''}
                  ${index === offers.length - 1 ? 'border-b' : ''}
                `}
              >
                <div className="sf-body">{Object.keys(term)[0]} months</div>
                <div className="sf-body">{term[Object.keys(term)[0]].max_range.formatted}</div>
                <div className="sf-body">{term[Object.keys(term)[0]].remaining.formatted}</div>
              </div>
            );
          })
        }
      </div>
      {minTerm}
      {pgMessage}
    </>
  );
}

ApprovedPaymentPlans.propTypes = {
  offers: PropTypes.arrayOf(PropTypes.node),
  formattedMinAllowedOrderAmount: PropTypes.string,
  pgRequired: PropTypes.bool,
};

ApprovedPaymentPlans.defaultProps = {
  offers: [],
  formattedMinAllowedOrderAmount: '',
  pgRequired: false,
};

export default ApprovedPaymentPlans;
