import React, {
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import {
  useFormik,
  FormikProvider,
} from 'formik';
import * as yup from 'yup';

import { useQuery, useMutation } from '@apollo/client';
import { omit, get, capitalize } from 'lodash';
import { toast } from 'react-toastify';

import { useDirectUploadFiles } from '../../../../utils/hooks';

import { CREATE_ORDER_CRM } from '../../../../graphql/queries/order';

import FormInput from '../../../../components/FormInput';
import ButtonToggle from '../../../../components/ButtonToggle';
import FormFieldMsg from '../../../../components/FormFieldMsg';
import DateInput from '../../../../components/DateInput';
import Button from '../../../../components/Button';
import MultiFileUpload from '../../../../components/MultiFileUpload';
import Select from '../../../../components/Select';
import AutoLoad from '../../../../components/AutoLoad';
import CustomNotification from '../../../../components/CustomNotification';

import {
  GET_CUSTOMER_PRIMARY_CONTACT,
} from '../../../../graphql/queries/customer';

import { CloseConfirmationModalContext, OrderNewContext } from '../../../../utils/contexts';
import { diffMonths, roundInTerms, formatCapital } from '../../../../utils/helpers';
import { availableTerms } from '../../../../api/utils';

const today = new Date();
today.setHours(0, 0, 0, 0);

const OrderFormSchema = ({ approvedOffers }) => {
  return (
    yup.object().shape({
      firstName: yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('This field is required'),
      lastName: yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('This field is required'),
      phone: yup.string()
        .required('This field is required'),
      email: yup.string()
        .typeError('Invalid email')
        .email('Invalid email')
        .required('This field is required'),
      amount: yup.number()
        .when(['startDate', 'endDate'], {
          is: (startDate, endDate) => Boolean(startDate && endDate),
          then: yup.number().test({
            name: 'same',
            exclusive: false,
            params: {},
            message: 'No offers are available for the selected term',
            test: function validateAmountWithOffers() {
              const { startDate, endDate } = this.parent;
              const datesSet = startDate && endDate && endDate > startDate;
        
              if (datesSet) {
                const offerTerm = diffMonths(startDate, endDate);
                const nearestTerm = roundInTerms(offerTerm);
                if (approvedOffers[nearestTerm] === undefined) return false;
              }
        
              return true;
            },
          }),
        }).required('Amount is required'),
      isExpansion: yup.boolean()
        .oneOf([true, false], 'This field is required')
        .nullable(),
      startDate: yup.date()
        .min(today, 'Date cannot be in the past')
        .required('This field is required'),
      endDate: yup.string()
        .required('This field is required'),
      orderForm: yup.array()
        .required('Order Form is required'),
      term: yup.string(),
      billingFrequency: yup.string()
        .required('This field is required'),
      paymentTerm: yup.number()
        .required('This field is required'),
    })
  );
};

function OrderForm({
  companyNumber,
  crmOpportunityId,
  crmOpportunityStage,
  crmOpportunityCloseDate,
  crmOpportunityProbability,
  offers,
  minAllowedOrderAmount,
}) {
  const location = useLocation();

  const closeConfirmationModalContext = useContext(CloseConfirmationModalContext);
  const { setOrderValues } = useContext(OrderNewContext);

  const useQueryParams = new URLSearchParams(location.search);
  const referrer = useQueryParams.get('referrer') || '';
  const application = useQueryParams.get('application') || '';

  const [createOrder, { loading: orderCreating }] = useMutation(CREATE_ORDER_CRM);
  const [allAvailableTerms, setAvailableTerms] = useState(false);
  const [directUploadFiles] = useDirectUploadFiles();
  const history = useHistory();

  const {
    data: customerDetails,
    loading: customerDetailsLoading,
  } = useQuery(GET_CUSTOMER_PRIMARY_CONTACT, {
    skip: !companyNumber,
    variables: {
      number: companyNumber,
    },
  });

  const [termOptions, setTermOptions] = useState([]);

  async function onSubmit(values, { setErrors, resetForm }) {
    try {
      const responseData = await createOrder({
        variables: {
          ...omit(values, [
            'validateOnMount',
            'orderForm',
            'term',
            'enableReinitialize',
            'validateOnChange',
            'validateOnBlur',
          ]),
        },
      });

      const errors = get(responseData, 'errors', {});

      if (Object.keys(errors).length) {
        setErrors(errors);
      } else {
        const orderId = get(responseData, 'data.createOrderCrm.id');
        const orderNumber = get(responseData, 'data.createOrderCrm.number');
        await directUploadFiles([...values.orderForm], {
          documentType: 'financing_order_form',
        }, {
          id: orderId,
          type: 'Order',
        });
        closeConfirmationModalContext.setNeedCloseConfirmation(false);
        resetForm();
        let message = 'Order form is submitted successfully! Upon approval, a checkout link will be sent to the customer automatically.';
        message += referrer === 'partner' ? '' : '\n Visit the Vartana widget for updates on the order.';
        toast.success(({ toastProps }) => {
          return (
            <CustomNotification
              type={toastProps.type}
              message={message}
            />
          );
        });
        history.push({
          pathname: '/forms/summary',
          search: `?customerNumber=${companyNumber}&orderNumber=${orderNumber}&referrer=${referrer}&application=${application}`,
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  const formInitialValues = useMemo(() => ({
    validateOnMount: true,
    validateOnChange: false,
    validateOnBlur: true,
    enableReinitialize: true,
    companyNumber,
    crmOpportunityId,
    crmOpportunityStage,
    crmOpportunityCloseDate,
    crmOpportunityProbability,
    companyName: formatCapital(get(customerDetails, 'company.name', '')),
    firstName: formatCapital(get(customerDetails, 'company.primaryUser.firstName', '')),
    lastName: formatCapital(get(customerDetails, 'company.primaryUser.lastName', '')),
    email: get(customerDetails, 'company.primaryUser.email', ''),
    phone: get(customerDetails, 'company.primaryUser.formattedPhone', ''),
    amount: '',
    startDate: null,
    isExpansion: null,
    endDate: '',
    term: '',
    orderForm: undefined,
  }), [
    companyNumber,
    crmOpportunityId,
    crmOpportunityStage,
    crmOpportunityCloseDate,
    crmOpportunityProbability,
    customerDetails,
  ]);

  const approvedOffers = useMemo(() => {
    let cleanedOffers = {};
    offers.forEach((e) => {
      cleanedOffers = { ...cleanedOffers, ...e };
    });
    return cleanedOffers;
  }, [offers]);

  const [schema, setSchema] = useState(() => OrderFormSchema({ approvedOffers }));

  useEffect(() => setSchema(OrderFormSchema({ approvedOffers })), [approvedOffers]);
  
  const formikBag = useFormik({
    initialValues: formInitialValues,
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (...args) => {
      const [values, helpers] = args;
      onSubmit(values, { ...helpers });
    },
  });

  const {
    isSubmitting,
    isValid,
    dirty,
    values: {
      startDate,
      isExpansion,
      endDate,
      companyName,
      firstName,
      lastName,
      email,
      amount,
      term,
      phone,
      billingFrequency,
      paymentTerm,
    },
    touched,
    errors: {
      startDate: startDateError,
    },
    setFieldValue,
    setFieldTouched,
    handleSubmit,
  } = formikBag;

  const cancelButton = useMemo(() => {
    if (referrer === 'partner') {
      return (
        <button type="button" className="block mt-4 mx-auto pointer-cursor" onClick={closeConfirmationModalContext.closeTab}>
          <p className="sf-text-link text-sf-blue">Cancel and close</p>
        </button>
      );
    }
    return null;
  }, [closeConfirmationModalContext.closeTab, referrer]);

  const {
    startDate: startDateTouched,
    endDate: endDateTouched,
    isExpansion: isExpansionTouched,
  } = touched;

  const datesSet = useMemo(() => startDate && endDate && (startDateTouched && endDateTouched) && endDate > startDate, [startDate, endDate, startDateTouched, endDateTouched]);
  const disabled = useMemo(() => isSubmitting || !(isValid && dirty), [isSubmitting, isValid, dirty]);
  const dataLoading = isSubmitting || orderCreating || customerDetailsLoading;

  const termDataSet = useMemo(() => (
    startDate
    && startDateTouched
    && customerDetails
    && isExpansionTouched
  ), [
    startDate,
    startDateTouched,
    customerDetails,
    isExpansionTouched,
  ]);

  const callApi = useCallback(async () => {
    const val = await availableTerms(startDate, isExpansion, companyNumber);
    const formattedTermOptions = val.terms.map((availableTerm, index) => ({
      id: index,
      value: availableTerm.value,
      label: availableTerm.label,
    }));
    setAvailableTerms(val);
    setTermOptions(formattedTermOptions);
  }, [startDate, isExpansion, companyNumber]);

  useEffect(() => {
    if (termDataSet) callApi();
  }, [termDataSet, callApi]);
  
  useEffect(() => {
    if (startDate && termOptions.length) {
      const option = termOptions.find((t) => t.value === endDate);
      if (option) {
        setFieldValue('endDate', get(option, 'value', ''));
      } else {
        setFieldValue('endDate', get(termOptions, '0.value', ''));
      }
    }
  }, [termOptions, startDate, endDate, setFieldValue]);

  useEffect(() => {
    const paymentTerms = get(customerDetails, 'company.creditAppraisal.allowedPaymentTerms', []);
    if (paymentTerms.length === 1) {
      setFieldValue('paymentTerm', get(paymentTerms, '0', ''));
    }
    const billingFrequencies = get(customerDetails, 'company.creditAppraisal.allowedBillingFrequencies', []);
    if (billingFrequencies.length === 1) {
      setFieldValue('billingFrequency', get(billingFrequencies, '0', ''));
    }
  }, [customerDetails, setFieldValue]);

  useEffect(() => {
    if (allAvailableTerms && endDate && isExpansion) {
      const selectedTerm = allAvailableTerms.terms.find((availableTerm) => availableTerm.value === endDate);
      setFieldValue('billingFrequency', get(selectedTerm, 'frequency', ''));
      setFieldValue('paymentTerm', get(selectedTerm, 'payment_term', ''));
    }
  }, [endDate, allAvailableTerms, setFieldValue, isExpansion]);

  useEffect(() => {
    setOrderValues({
      startDate,
      isExpansion,
      endDate,
      companyNumber,
      billCycleDay: get(customerDetails, 'company.billCycleDay', ''),
      companyName: formatCapital(get(customerDetails, 'company.name', '')),
      firstName,
      lastName,
      phone,
      email,
      amount,
      term,
      entityType: get(customerDetails, 'company.entityType', ''),
      billingFrequency,
      paymentTerm,
    });
  }, [startDate, isExpansion, endDate, companyNumber, companyName, customerDetails, firstName, lastName, email, phone, amount, term, billingFrequency, paymentTerm, setOrderValues]);

  closeConfirmationModalContext.setNeedCloseConfirmation(dirty);

  const highlightedDates = useMemo(() => {
    const billCycleDay = get(customerDetails, 'company.billCycleDay', null);
    
    if (billCycleDay && isExpansion) {
      const dates = new Array(12).fill(undefined).map((item, index) => {
        const date = new Date();
        date.setDate(billCycleDay);
        date.setMonth(date.getMonth() + index);
        return date;
      });
      return [
        {
          'border rounded border-sf-vartana-dark-purple': dates,
        },
      ];
    }

    return [];
  }, [customerDetails, isExpansion]);

  const [canSelectCoTerm, setCanSelectCoTerm] = useState(false);

  useEffect(() => {
    const canCoTerm = get(customerDetails, 'company.canCoTerm', null);
    setCanSelectCoTerm(canCoTerm);
    if (canCoTerm) {
      setFieldValue('isExpansion', null);
      setFieldTouched('isExpansion', false);
    } else {
      setFieldValue('isExpansion', false);
      setFieldTouched('isExpansion', true);
    }
  }, [customerDetails, setFieldValue, setFieldTouched]);

  const termSelectors = useMemo(() => {
    if (canSelectCoTerm) {
      return (
        <div>
          <ButtonToggle
            name="isExpansion"
            className="flex-row"
            label="Is this a co-term order?"
            buttonProps={[{ label: 'Yes', value: true }, { label: 'No', value: false }]}
            tooltip="
              New request amount will be <br /> 
              distributed equally into the <br /> 
              remaining term or invoices.
            "
          />
        </div>
      );
    }
    return null;
  }, [canSelectCoTerm]);

  const formattedFrequencies = useMemo(() => {
    const allowedFrequencies = get(customerDetails, 'company.creditAppraisal.allowedBillingFrequencies', []);
    return allowedFrequencies.map((billing) => {
      return { label: capitalize(billing), value: billing };
    });
  }, [customerDetails]);

  const frequencySelector = useMemo(() => {
    const allowedFrequencies = formattedFrequencies;
    if (allowedFrequencies.length > 1) {
      return (
        <div>
          <ButtonToggle
            name="billingFrequency"
            className="flex-row"
            label="Invoice frequency"
            buttonProps={allowedFrequencies}
          />
        </div>
      );
    }
    return null;
  }, [formattedFrequencies]);

  const formattedPayments = useMemo(() => {
    const paymentTerms = get(customerDetails, 'company.creditAppraisal.allowedPaymentTerms', []);
    const formattedTerms = get(customerDetails, 'company.creditAppraisal.formattedAllowedPaymentTerms', '');
    return paymentTerms.map((payment) => {
      const selectedTerm = formattedTerms.find((element) => {
        return element.value === payment;
      });
      return { label: get(selectedTerm, 'formatted', '--'), value: payment };
    });
  }, [customerDetails]);

  const termSelector = useMemo(() => {
    const paymentTermOptions = formattedPayments;
    if (paymentTermOptions.length > 1) {
      return (
        <div>
          <ButtonToggle
            name="paymentTerm"
            className="flex-row"
            label="Payment term"
            buttonProps={paymentTermOptions}
          />
        </div>
      );
    }
    return null;
  }, [formattedPayments]);

  const termControls = useMemo(() => {
    if (isExpansionTouched) {
      return (
        <div className="space-y-4">
          <div className="flex flex-row space-x-4">
            <div className="flex-1">
              <DateInput
                name="startDate"
                highlightDates={highlightedDates}
                placeholder="MM/DD/YYYY"
                label="Start date"
              />
              <FormFieldMsg
                show={isExpansion && get(customerDetails, 'company.billCycleDay', false) && !(startDateError && startDateTouched)}
                msg="Purple dates on calendar are existing billing dates"
                className="sf-field-helper-text mt-2 text-sf-vartana-gray-60 font-normal"
              />
            </div>
            <div className="flex-1">
              <Select
                id="endDate"
                name="endDate"
                label={`${isExpansion ? 'Existing terms ending on' : 'Term and end date'}`}
                placeholder="Select"
                value={endDate}
                options={termOptions}
                className="flex-1"
              />
            </div>
          </div>

          <div className="flex flex-row">
            <FormInput
              name="amount"
              label="Amount (TCV)"
              type="number"
              prefix="$"
              className="flex-1"
              onChange={(e, { setValue }) => {
                const { value } = e.target;
                if (value) {
                  setValue(Math.abs(e.target.value));
                } else {
                  setValue(e.target.value);
                }
              }}
              tooltip="
                Amount outside of approved <br>
                terms will be subject to <br>
                review by Vartana.
              "
              onBlur={(e, { setWarningMsg }) => {
                const { value } = e.target;
                if (datesSet) {
                  const offerTerm = diffMonths(startDate, endDate);
                  const nearestTerm = roundInTerms(offerTerm);

                  if (nearestTerm in approvedOffers && value) {
                    const currentTerm = approvedOffers[nearestTerm];
                    if (value < minAllowedOrderAmount || value > currentTerm?.remaining?.amount) {
                      setWarningMsg('Amount outside of approved terms will be subject to review by Vartana.');
                      return;
                    }
                  }
                }
                setWarningMsg('');
              }}
            />
          </div>
        </div>
      );
    }
    return null;
  }, [
    isExpansionTouched,
    startDate,
    endDate,
    highlightedDates,
    isExpansion,
    customerDetails,
    startDateError,
    startDateTouched,
    termOptions,
    minAllowedOrderAmount,
    datesSet,
    approvedOffers,
  ]);

  return (
    <>
      <h1 className="sf-h1 text-sf-vartana-black">Order detail</h1>
      <p className="normal-text mt-2">Complete signer&apos;s contact and order information.</p>
      <FormikProvider value={formikBag}>
        <form
          className="mt-4"
          onSubmit={handleSubmit}
        >
          <fieldset disabled={dataLoading}>
            <FormInput
              className="tw-hidden"
              name="companyNumber"
              type="hidden"
            />
            <FormInput
              className="tw-hidden"
              name="crmOpportunityId"
              type="hidden"
            />
            <FormInput
              className="tw-hidden"
              name="crmOpportunityStage"
              type="hidden"
            />
            <FormInput
              className="tw-hidden"
              name="crmOpportunityCloseDate"
              type="hidden"
            />
            <FormInput
              className="tw-hidden"
              name="crmOpportunityProbability"
              type="hidden"
            />
            {/* subform */}
            <div className="space-y-4">
              <div className="flex flex-row space-x-4">
                <FormInput
                  name="companyName"
                  label="Company name"
                  type="text"
                  disabled
                  className="flex-1"
                />
              </div>
              <div className="flex flex-row space-x-4">
                <FormInput
                  name="firstName"
                  label="Legal first name"
                  type="text"
                  className="flex-1"
                />

                <FormInput
                  name="lastName"
                  label="Legal last name"
                  type="text"
                  className="flex-1"
                />
              </div>

              <div className="flex flex-row space-x-4">
                <FormInput
                  name="email"
                  label="Signer's email"
                  type="text"
                  className="flex-1"
                />
                
                <FormInput
                  name="phone"
                  placeholder="+1 (###) ###-####"
                  label="Phone"
                  type="text"
                  mask="+1 (999) 999-9999"
                  disableFullstoryRecording
                  className="flex-1"
                />
              </div>
              {termSelectors}
              {termControls}
              {termSelector}
              {frequencySelector}
            </div>

            {/* subform */}
            <div className="mt-6">
              <h1 className="sf-h1 text-sf-vartana-black">Documents</h1>

              <div className="flex flex-row space-y-4">
                <div className="flex-1">
                  <p className="field-label text-sf-vartana-gray-60 mt-2">Order form</p>
                  <MultiFileUpload name="orderForm" />
                </div>
              </div>
            </div>

            <div className="flex flex-row justify-center mt-6">
              <AutoLoad loading={dataLoading}>
                <Button
                  type="submit"
                  disabled={disabled}
                  element={(
                    <p className={`sf-button-text text-white ${disabled ? 'bg-sf-vartana-gray-40' : 'bg-sf-blue'} px-24 py-2 rounded`}>Submit</p>
                  )}
                />
              </AutoLoad>
            </div>
            {cancelButton}
          </fieldset>
        </form>
      </FormikProvider>

    </>
  );
}

OrderForm.propTypes = {
  companyNumber: PropTypes.string.isRequired,
  crmOpportunityId: PropTypes.string,
  crmOpportunityStage: PropTypes.string,
  crmOpportunityCloseDate: PropTypes.string,
  crmOpportunityProbability: PropTypes.string,
  offers: PropTypes.arrayOf(PropTypes.node).isRequired,
  minAllowedOrderAmount: PropTypes.number,
};

OrderForm.defaultProps = {
  crmOpportunityId: '',
  crmOpportunityStage: '',
  crmOpportunityCloseDate: '',
  crmOpportunityProbability: '',
  minAllowedOrderAmount: 0,
};

export default OrderForm;
