import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import { useField } from 'formik';
import FormLabel from '../FormLabel';
import FormFieldMsg from '../FormFieldMsg';

function ButtonToggle({
  name, 
  className,
  tooltip,
  label,
  buttonProps,
}) {
  const [field, { touched, error }, { setValue, setTouched }] = useField(name);

  const styleClasses = useMemo(() => ({
    default: {
      text: 'placeholder-sf-vartana-gray-40',
      label: 'text-sf-vartana-gray-60',
    },
    error: {
      text: 'text-sf-alert placeholder-sf-alert',
      label: 'text-sf-alert',
    },
  }), []);

  const formStateClasses = useMemo(() => {
    let classes = styleClasses.default;
    if (touched && error) {
      classes = styleClasses.error;
    }
    return classes;
  }, [styleClasses, touched, error]);

  const onSelect = (value) => {
    setTouched(true);
    setValue(value);
  };

  return (
    <>
      <div className={`${className}`}>
        <FormLabel containerClassName="mb-2" labelClassName={`${formStateClasses.label}`} name={field.name} label={label} tooltip={tooltip} />
        <div className="flex flex-row space-x-2">
          { 
            buttonProps.map((buttonProp) => (
              <div className="flex-1">
                <button
                  type="button" 
                  className={`w-full rounded-full focus:outline-none text-center p-3 sf-text-link-bold ${
                    field.value === buttonProp.value ? 'bg-sf-vartana-blue-40 text-white' : 'bg-sf-light-blue-20 text-sf-vartana-blue-80'
                  }`}
                  onClick={() => onSelect(buttonProp.value)}
                >
                  {buttonProp.label}
                </button>
              </div>
            ))
          }
        </div>
      </div>
      <FormFieldMsg show={touched} msg={error} className={`${formStateClasses.text} mt-2`} />
    </>
  );
}

ButtonToggle.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  tooltip: PropTypes.string,
  label: PropTypes.string,
  buttonProps: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  })).isRequired,
};

ButtonToggle.defaultProps = {
  name: '',
  className: '',
  tooltip: '',
  label: '',
};

export default ButtonToggle;
