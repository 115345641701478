/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */

import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { useField } from 'formik';

import FormLabel from '../FormLabel';
import FormFieldMsg from '../FormFieldMsg';

function isEncrypted(value = '') {
  return value.includes('*');
}

function FormInputField({ mask, inputProps }) {
  if (mask && !isEncrypted(inputProps.value)) {
    return (
      <InputMask 
        mask={mask} 
        maskPlaceholder={inputProps.placeholder}
        {...inputProps}
      />
    );
  }
  return (
    <input
      {...inputProps}
    />
  );
}

function Prefix({ symbol, className = '' }) {
  if (symbol) return <i className={`absolute text-center top-2/4 transform-gpu -translate-y-2/4 pl-3 not-italic pointer-events-none ${className}`}>{symbol}</i>;
  return null;
}
function FormInput({ 
  name,
  placeholder,
  label,
  type,
  value,
  className,
  disabled,
  mask,
  prefix,
  disableFullstoryRecording,
  tooltip,
  onBlur,
  onChange,
}) {
  const [field, meta, { setValue }] = useField(name);
  const [warningMsg, setWarningMsg] = useState('');

  const defaultClasses = useMemo(() => 'border border-solid rounded', []);
  const disabledClasses = useMemo(() => 'cursor-not-allowed bg-sf-disabled border-none', []);

  const styleClasses = useMemo(() => ({
    default: {
      borders: 'border-sf-vartana-gray-40',
      text: 'placeholder-sf-vartana-gray-40',
      label: 'text-sf-vartana-gray-60',
    },
    error: {
      borders: 'border-red-400 focus:outline-none focus:ring-0 focus:border-current',
      text: 'text-red-400 placeholder-red-400',
      label: 'text-red-400',
    },
    warning: {
      borders: 'border-sf-vartana-dark-mustard focus:outline-none focus:ring-0 focus:border-current',
      text: 'text-sf-vartana-dark-mustard placeholder-sf-vartana-dark-mustard',
      label: 'text-sf-vartana-dark-mustard',
    },
  }), []);

  const formStateClasses = useMemo(() => {
    let classes = styleClasses.default;
    if (meta.touched && meta.error) {
      classes = styleClasses.error;
    } else if (meta.touched && warningMsg) {
      classes = styleClasses.warning;
    }
    return classes;
  }, [styleClasses, meta, warningMsg]);

  const inputProps = {
    ...field,
    type,
    name: field.name,
    id: field.name,
    value: value || field.value,
    placeholder,
    disabled,
    onChange: (e) => {
      setValue(e.target.value);
      onChange(e, { setValue, setWarningMsg });
    },
    onBlur: (e) => {
      field.onBlur(e);
      onBlur(e, { setWarningMsg });
    },
    onWheel: (e) => {
      if (e.target.type === 'number') {
        e.target.blur();
  
        // Prevent the page/container scrolling
        e.stopPropagation();
  
        // Refocus immediately
        setTimeout(() => {
          e.target.focus();
        }, 0);
      }
    },
    className: `
      py-2
      w-full
      field-text
      ${defaultClasses}
      ${disabled ? disabledClasses : ''}
      ${prefix ? 'pl-6' : 'pl-3'}
      ${disableFullstoryRecording ? 'fs-mask' : ''}
      ${formStateClasses.borders}
      ${formStateClasses.text}
    `,
  };

  return (
    <div className={`${className}`}>
      <FormLabel containerClassName="mb-2" labelClassName={`${formStateClasses.label}`} name={field.name} label={label} tooltip={tooltip} />
      <div className={`${prefix ? 'relative' : ''} rounded-md shadow-sm`}>
        <Prefix className={formStateClasses.label} symbol={prefix} />
        <FormInputField mask={mask} inputProps={inputProps} />
      </div>
      <FormFieldMsg show={meta.touched} msg={meta.error || warningMsg} className={`${formStateClasses.text} mt-4`} />
    </div>
  );
}


FormInput.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  mask: PropTypes.string,
  className: PropTypes.string,
  prefix: PropTypes.string,
  disableFullstoryRecording: PropTypes.bool,
  tooltip: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};

FormInput.defaultProps = {
  name: '',
  placeholder: '',
  label: '',
  type: '',
  value: '',
  disabled: false,
  mask: '',
  className: '',
  prefix: '',
  disableFullstoryRecording: false,
  tooltip: '',
  onBlur: () => '',
  onChange: () => {},
};

export default FormInput; 
