import React from 'react';
import NeedInfoIcon from '../../../../assets/need_info.svg';

function PgRequired() {
  return (
    <div className="flex flex-row justify-center">
      <div className="flex items-center">
        <img className="h-4 pr-2" src={NeedInfoIcon} alt={NeedInfoIcon} />
        <p className="text-sf-vartana-dark-mustard text-xs">
          Personal guaranty required at checkout
        </p>
      </div>
    </div>
  );
}

export default PgRequired;
