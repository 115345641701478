import { gql } from '@apollo/client';

export const SEND_APPLICATION = gql`
  mutation (
    $companyNumber: String!
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String
    $message: String!
  ) {
    sendAppraisalApplication(
      companyNumber: $companyNumber
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      message: $message
    ) {
      id
    }
  }
`;

export const CREATE_CUSTOMER = gql`
    mutation(
        $name: String!,
        $entityType: String,
        $street: String!,
        $city: String!,
        $state: String!,
        $zip: String!,
        $firstName: String,
        $lastName: String,
        $email: String,
        $phone: String,
        $annualRevenue: Float!,
        $employeeCount: Int!,
        $maxUnits: Int,
        $crmId: String,
        $crmAccountId: String
        $crmOpportunityId: String
        $lastOpportunityStage: String
        $lastOpportunityProbability: Float
        $lastOpportunityCloseDate: ISO8601Date
        $lastOpportunityAmount: Float
        $requestedAmount: Float,
        $requestedTerm: Int,
    ) {
        createCustomer(
            name: $name
            entityType: $entityType
            street: $street
            city: $city
            state: $state
            zip: $zip
            firstName: $firstName
            lastName: $lastName
            email: $email
            phone: $phone
            annualRevenue: $annualRevenue
            employeeCount: $employeeCount
            maxUnits: $maxUnits
            crmId: $crmId
            crmAccountId: $crmAccountId
            crmOpportunityId: $crmOpportunityId
            lastOpportunityStage: $lastOpportunityStage
            lastOpportunityProbability: $lastOpportunityProbability
            lastOpportunityCloseDate: $lastOpportunityCloseDate
            lastOpportunityAmount: $lastOpportunityAmount
            requestedAmount: $requestedAmount
            requestedTerm: $requestedTerm
        ) {
          number
        }
    }
`;

export const UPDATE_CUSTOMER = gql`
  mutation(
      $number: String!,
      $name: String,
      $entityType: String,
      $addressId: ID,
      $street: String,
      $city: String,
      $state: String,
      $zip: String,
      $ein: String,
      $contactNumber: String,
      $firstName: String,
      $lastName: String,
      $email: String,
      $phone: String,
      $annualRevenue: Float,
      $employeeCount: Int,
      $maxUnits: Int,
      $requestedAmount: Float,
      $requestedTerm: Int,
  ) {
    updateCustomer(
      number: $number
        name: $name
        entityType: $entityType
        addressId: $addressId
        street: $street
        city: $city
        state: $state
        zip: $zip
        ein: $ein
        contactNumber: $contactNumber
        firstName: $firstName
        lastName: $lastName
        email: $email
        phone: $phone
        annualRevenue: $annualRevenue
        employeeCount: $employeeCount
        maxUnits: $maxUnits
        requestedAmount: $requestedAmount
        requestedTerm: $requestedTerm
    ) {
      number
    }
  }
`;

export const GET_CUSTOMER_BY_NUMBER = gql`
  query($number: String!) {
    company(number: $number) {
      id
      number
      status
      name
      discount
      defaultAddressId
      street
      city
      state
      zip
      entityType
      annualRevenue
      employeeCount
      maxUnits
      formattedAmount
      formattedFee
      formattedFeePercentage
      billCycleDay
      createdAt
      seller {
        orderLinkEnabled
        proposalEnabled
        product {
          allowedPaymentFrequencies
          allowedPaymentTerms
        }
      }
      buyerOrders {
        number
        amount
        fee
        formattedAmount
        formattedFee
        formattedFeePercentage
        term
        formattedTerm
        status
        loanDecision
        updatedAt
        user {
          fullName
        }
      }
      users {
        number
        fullName
        email
        phone
        formattedPhone
        isOwner
        primary
        receiveInvoice
      }
      invoices {
        amount
        number
        url
        formattedAmount
        dueDate
        status
        orders {
          number
        }
        payments {
          number
        }
      }
      paymentScheduleItems {
        dueDate
        payment
        formattedPayment
        status
        invoices {
          number
          url
        }
        payments {
          number
        }
        invoice {
          number
        }
      }
      primaryUser {
        number
        firstName
        lastName
        email
        phone
        formattedPhone
      }
      creditAppraisal {
        id
        number
        currentStepDetails
        emailHistory
        moreInfo
        approvedCreditTermsFormatted
        pgRequired
        maxTerm
        applicationRequired
        loanDecision
        expired
        userDocuments {
          id
          type
          url
          createdAt
        }
      }
      utilizedCredit
      formattedUtilizedCredit
      remainingCredit
      formattedRemainingCredit
      paymentsCollectedOverPredictedPayments
      crmUrl
      crmId
      newOrderLimits
      maxTerm
    }
  }
`;

export const GET_CUSTOMER_PRIMARY_CONTACT = gql`
  query($number: String!) {
    company(number: $number) {
      number
      name
      entityType
      billCycleDay
      canCoTerm
      creditAppraisal {
        allowedPaymentTerms
        allowedBillingFrequencies
        formattedAllowedPaymentTerms
      }
      primaryUser {
        number
        firstName
        lastName
        email
        phone
        formattedPhone
      }
    }
  }
`;

export const GENERATE_PROPOSAL = gql`
  mutation($customerNumber: String!, $opportunityAmount: Float!) {
    generateProposal(
      customerNumber: $customerNumber
      opportunityAmount: $opportunityAmount
    ) {
      id
      number
      encodedProposal
    }
  }
`;
