/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/autocomplete-valid */

import React, { 
  useState, 
  useRef,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

import FormLabel from '../FormLabel';
import FormFieldMsg from '../FormFieldMsg';

import poweredByGoogle from '../../assets/powered_by_google.png';

import { placesSuggestions, placeDetails } from '../../api/google';

import { useWindowSize } from '../../utils/hooks';

function SearchAddress({ 
  name,
  placeholder,
  label,
  value,
  containerClassName,
  afterPlaceSelect,
}) {
  useWindowSize();
  const [field, meta, { setValue }] = useField(name);

  const elementRef = useRef();
  const inputRef = useRef();

  const [addresses, setaddresses] = useState([]); 
  const [cursor, setCursor] = useState(0);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [debounceId, setDebounceId] = useState(null);
  const [addressComponents, setAddressComponents] = useState({
    city: '',
    state: { shortName: '', longName: '' },
    zip: '',
  });

  const defaultClasses = useMemo(() => 'border border-solid rounded', []);

  const styleClasses = useMemo(() => ({
    default: {
      borders: 'border-sf-vartana-gray-40',
      text: 'placeholder-sf-vartana-gray-40',
      label: 'text-sf-vartana-gray-60',
    },
    error: {
      borders: 'border-red-400 focus:outline-none focus:ring-0 focus:border-current',
      text: 'text-red-400 placeholder-red-400',
      label: 'text-red-400',
    },
  }), []);

  const formStateClasses = useMemo(() => {
    let classes = styleClasses.default;
    if (meta.touched && meta.error) {
      classes = styleClasses.error;
    }
    return classes;
  }, [styleClasses, meta]);

  const isInputFocused = useCallback(() => document?.activeElement === inputRef?.current, []);

  const loadPlaceSuggestions = useCallback((inputValue) => {
    placesSuggestions(inputValue, (predictions) => {
      if (predictions.length > 0) {
        setaddresses(
          predictions.map((prediction) => ({
            label: prediction?.description,
            value: prediction?.structured_formatting?.main_text,
            placeId: prediction?.place_id,
          })),
        );
        if (cursor > predictions.length - 1) setCursor(predictions.length - 1);
        if (isInputFocused()) setDropdownVisible(true);
      } else {
        setDropdownVisible(false);
      }
    });
  }, [cursor, isInputFocused]);

  const onPlaceSelect = useCallback((address) => {
    setDropdownVisible(false);
    setValue(address.value);
    placeDetails(address.placeId, (place) => {
      if (place) {
        const newAddressComponents = { ...addressComponents };
        place.address_components.forEach((component) => {
          const { types } = component;
          if (types.includes('administrative_area_level_1')) {
            newAddressComponents.state.longName = component.long_name;
            newAddressComponents.state.shortName = component.short_name;
          } else if (types.includes('postal_code')) {
            newAddressComponents.zip = component.long_name;
          } else if (types.includes('locality')) {
            newAddressComponents.city = component.long_name;
          }
        });
        setAddressComponents(newAddressComponents);
        afterPlaceSelect(newAddressComponents);
      }
    });
  }, [addressComponents, afterPlaceSelect, setValue]);

  const inputProps = {
    ...field,
    className: `
      py-2
      w-full
      sf-field-text
      pl-3
      ${defaultClasses}
      ${formStateClasses.borders}
      ${formStateClasses.text}
    `,
    type: 'text',
    name: field.name,
    id: field.name,
    value: value || field.value,
    placeholder,
    autoComplete: 'nope',
    ref: inputRef,
    onChange: (e) => {
      const inputValue = e.target.value;
      if (inputValue.length === 0) setaddresses([]);
      setValue(inputValue);
      if (debounceId) clearTimeout(debounceId);
      setDebounceId(setTimeout(() => loadPlaceSuggestions(inputValue), 1000));
    },
    onBlur: (e) => {
      field.onBlur(e);
      setDropdownVisible(false);
    },
    onKeyDown(e) {
      if (e.key === 'ArrowUp' && cursor > 0) {
        setCursor((prevCursor) => prevCursor - 1);
      } else if (e.key === 'ArrowDown' && cursor < addresses.length - 1) {
        setCursor((prevCursor) => prevCursor + 1);
      } else if (e.key === 'Enter' && addresses.length > 0) {
        onPlaceSelect(addresses[cursor]);
      }
    },
  };

  return (
    <div ref={elementRef} className={containerClassName}>
      <FormLabel 
        containerClassName="mb-2" 
        labelClassName={`${formStateClasses.label}`}
        name={field.name} 
        label={label} 
      />
      <div className="rounded-md shadow-sm">
        <input
          {...inputProps}
        />
      </div>
      <FormFieldMsg show={meta.touched && meta.error} msg={meta.error} className={`${formStateClasses.text} mt-2`} />
      { dropdownVisible && addresses.length > 0 ? (
        <div 
          className={`
            mt-2
            absolute
            m-0
            bg-white
            border
            border-gray-300
            rounded-md
            shadow-sm
            z-10
          `}
          style={{
            width: elementRef?.current?.clientWidth || 0,
          }}
        >
          <ul role="listbox" tabIndex="0">
            {addresses.map((address, index) => (
              <li 
                aria-selected
                className={`
                  px-3
                  cursor-pointer
                  z-20
                  ${cursor === index ? 'bg-sf-blue text-white' : ''}
                `}
                key={address.placeId}
                role="option"
                onMouseDown={() => onPlaceSelect(address)}
                onMouseEnter={() => setCursor(index)}
              >
                <div className="py-3">
                  {address.label}
                </div>
              </li>
            ))}
          </ul>
          <div className="ml-auto w-auto p-2">
            <img
              src={poweredByGoogle}
              alt="powered by google" 
              className="ml-auto w-auto h-4"
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}

SearchAddress.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  afterPlaceSelect: PropTypes.func,
  withError: PropTypes.bool,
};

SearchAddress.defaultProps = {
  name: '',
  placeholder: '',
  label: '',
  type: '',
  value: '',
  className: '',
  containerClassName: '',
  afterPlaceSelect: () => {},
  withError: true,
};

export default SearchAddress;
