import moment from 'moment';
import { mapKeys, camelCase, capitalize } from 'lodash';
import { FileChecksum } from '@rails/activestorage/src/file_checksum';

export const initializeNullValuesToEmptyStrings = (obj) => JSON.parse(JSON.stringify(obj, (k, v) => (v === null ? '' : v)));
export const parseDateStringToDateFormat = (obj) => (obj ? moment(obj).format('MM/DD/YYYY') : obj);
export const humanDateFormat = (dateString) => (dateString ? moment(dateString).format('MMM D, YYYY @ h:mmaz') : '');

export const mapGraphqlErrorsToObject = (errors = []) => {
  const mapped = {};
  if (errors) {
    Object.entries(errors).forEach(([key, val]) => {
      mapped[camelCase(key)] = val.join(',');
    });
  }
  return mapped;
};

export const cleanPhoneNumber = (phoneNumber = '') => {
  const cleaned = phoneNumber
    .replace(/\s+/g, '') // remove spaces
    .replace(/[\])}[{(]/g, '') // remove braces ()
    .replace(/-/g, ''); // remove hyphens
  return cleaned;
};

export const getMonthsDifference = (year1, year2) => {
  return moment(year1).diff(moment(year2), 'months', true);
};

export const mapKeysToCamelCase = (obj) => mapKeys(obj, (v, k) => camelCase(k));

export const maskString = (str) => `x${str.substr(str.length - 4)}`;

export const calculateChecksum = (file) => {
  return new Promise((resolve, reject) => {
    FileChecksum.create(file, (error, checksum) => {
      if (error) reject(error);
      else resolve(checksum);
    });
  });
};

export const calculateFilesMetadata = (files) => {
  return new Promise((resolve, reject) => {
    const pendingJobs = files.map((file) => calculateChecksum(file));
    Promise.all(pendingJobs).then((checksums) => {
      return files.map((file, index) => ({
        filename: file.name,
        byteSize: file.size,
        checksum: checksums[index],
        contentType: file.type,
      }));
    }).then((filesMetadata) => {
      resolve(filesMetadata);
    }).catch((error) => reject(error));
  });
};

export const getAPIURL = () => {
  const location = window.location.hostname;
  return location.includes('com') ? process.env.REACT_APP_API_NEW_URL : process.env.REACT_APP_API_URL;
};

export function diffMonths(startDate, endDate) {
  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);

  const startDateString = moment(startDateObj).format('YYYY-MM-DD');
  const endDateString = moment(endDateObj).format('YYYY-MM-DD');

  return moment(endDateString).diff(moment(startDateString), 'months', true);
}

export function roundInTerms(number) {
  return Math.ceil(number / 12) * 12;
}

export function getAllowedTerms(terms) {
  return terms.map((term) => {
    return { value: term, label: `${term} months` };
  });
}

export function getUSDateFormat(dateString) {
  if (dateString) {
    return moment(dateString).format('MM/DD/YYYY');
  }
  return '';
}

export function formatCapital(title) {
  return capitalize(title);
}
