import { getAPIURL } from '../utils/helpers';

export const setVendor = (values) => {
  return fetch(`${getAPIURL()}/hubspot/set_vendor`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(values),
    credentials: 'include',
  });
};
