import React from 'react';
import { 
  ApolloClient,
  InMemoryCache, 
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';
import { toast } from 'react-toastify';
import { get, capitalize } from 'lodash';

import { mapGraphqlErrorsToObject, getAPIURL } from '../utils/helpers';
import history from '../utils/browser_history';

import CustomNotification from '../components/CustomNotification';

const extensions = {
  SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  VALIDATION_ERROR: 'VALIDATION_ERROR',
  AUTHENTICATION_ERROR: 'AUTHENTICATION_ERROR',
  THANK_YOU: 'THANK_YOU',
  ALREADY_EXISTS: 'ALREADY_EXISTS',
};

function showErrorNotification(message) {
  toast.error(({ toastProps }) => <CustomNotification type={toastProps.type} message={message} />);
}

const onErrorLink = onError(({
  response, 
  graphQLErrors, 
  networkError, 
}) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((err) => {
      switch (err?.extensions?.code) {
      case extensions.VALIDATION_ERROR: {
        const parsedErrors = JSON.parse(err.message);
        response.errors = mapGraphqlErrorsToObject(parsedErrors);
        break;
      }
      case extensions.ALREADY_EXISTS: {
        const parsedErrors = JSON.parse(err.message);
        showErrorNotification(capitalize(get(parsedErrors, 'base.0', 'Error')));
        break;
      }
      case extensions.AUTHENTICATION_ERROR: {
        if (!['thank-you', '/crm/connect'].includes(history.location.pathname)) {
          history.push({
            pathname: '/error',
            state: {
              errorTitle: 'Unauthorized', 
              errorMsg: 'Not allowed',
            },
          });
        }
        break;
      }
      case extensions.SERVER_ERROR: {
        history.push({
          pathname: '/error',
        });
        break;
      }
      default:
      }
    });
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

const httpLink = createUploadLink({
  uri: `${getAPIURL()}/crm/graphql`,
  credentials: 'include',
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: onErrorLink.concat(httpLink),
  defaultOptions,
});

export default client;
