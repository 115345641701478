import React, {
  useCallback,
  useEffect,
} from 'react';

import { 
  Formik, 
  Form, 
} from 'formik';
import * as yup from 'yup';
import { get } from 'lodash';
import { useMutation, useReactiveVar } from '@apollo/client';
import {
  useLocation,
} from 'react-router-dom';

import { useIntercom } from 'react-use-intercom';
import * as FullStory from '@fullstory/browser';

import {
  GENERATE_PROPOSAL,
} from '../../../../graphql/queries/customer';
import { sessionVar } from '../../../../graphql/cache';

import AutoLoad from '../../../../components/AutoLoad';
import FormInput from '../../../../components/FormInput';
import Button from '../../../../components/Button';

const GenerateProposalFormSchema = yup.object().shape({
  opportunityAmount: yup.number()
    .positive('Amount must be greater than zero')
    .required('This field is required'),
});

function Proposal() {
  const [generateProposal, { loading: isGeneratingProposal }] = useMutation(GENERATE_PROPOSAL);
  const location = useLocation();
  const useQueryParams = new URLSearchParams(location.search);
  const customerNumber = useQueryParams.get('customerNumber');
  const application = useQueryParams.get('application');
  const { update: updateIntercom } = useIntercom();

  const onProposalSubmit = useCallback(async (values) => {
    try {
      const response = await generateProposal({
        variables: {
          ...values,
        },
      });
      const proposal = get(response, 'data.generateProposal.encodedProposal');
      const linkSource = `data:application/pdf;base64,${proposal}`;
      const downloadLink = document.createElement('a');
      const fileName = `${get(response, 'data.generateProposal.number')}-Proposal.pdf`;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    } catch (e) {
      console.log(e);
    }
  }, [generateProposal]);

  const sessionData = useReactiveVar(sessionVar);

  useEffect(() => {
    const user = get(sessionData, 'session.user', null);
    const companyUrlAdmin = !customerNumber ? '' : `${process.env.REACT_APP_ADMIN_URL}/admin/companies/?q%5Bnumber_contains%5D=${customerNumber}&commit=Filter&order=id_desc`;
    if (user) {
      const fullName = `${user.firstName} ${user.lastName}`;
      FullStory.identify(user.email, {
        customer: fullName,
        displayName: fullName,
        email: user.email,
      });
      updateIntercom({
        name: application === 'hubspot' ? '' : fullName,
        email: user.email,
        created_at: new Date(user.createdAt).valueOf(),
        customAttributes: {
          application: 'widget-app',
          environment: process.env.REACT_APP_NODE_ENV,
          resourceId: user.id,
          resourceType: 'User',
          companyUrlAdmin,
        },
      });
    }
  }, [customerNumber, sessionData, updateIntercom, application]);

  return (
    <AutoLoad loading={isGeneratingProposal}>
      <div className="p-16">
        <div className="space-y-2">
          <h1 className="sf-h1 text-sf-vartana-black">Create proposal</h1>
          <p className="normal-text mt-2">Please enter sales order amount to create proposal.</p>
        </div>
        <div className="mt-6">
          <Formik
            initialValues={{
              validateOnMount: true,
              customerNumber,
              opportunityAmount: '',
            }}
            enableReinitialize
            validationSchema={GenerateProposalFormSchema}
            onSubmit={onProposalSubmit}
          >
            {({
              isSubmitting, 
              isValid, 
              dirty,
            }) => {
              const submitDisabled = isSubmitting || isGeneratingProposal || !(isValid && dirty);

              return (
                <Form>
                  <fieldset>
                    <FormInput
                      className="tw-hidden"
                      name="customerNumber"
                      type="hidden"
                    />

                    <div className="space-y-4">
                      <div className="flex flex-row space-x-4">
                        <FormInput
                          name="opportunityAmount"
                          label="Opportunity Amount"
                          type="number"
                          className="flex-1"
                          prefix="$"
                          onChange={(e, { setValue }) => {
                            const { value } = e.target;
                            if (value) setValue(Math.abs(value));
                            else setValue(value);
                          }}
                        />

                        <Button
                          type="submit"
                          disabled={isGeneratingProposal || submitDisabled}
                          element={(
                            <p className={`mt-6 pt-2 sf-button-text text-white ${isGeneratingProposal || submitDisabled ? 'bg-sf-vartana-gray-40' : 'bg-sf-blue'} px-24 py-2 rounded`}>Download</p>
                          )}
                        />
                      </div>
                    </div>
                  </fieldset>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </AutoLoad>
  );
}

export default Proposal;
