import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { 
  Formik, 
  Form, 
} from 'formik';
import * as yup from 'yup';

import { useMutation } from '@apollo/client';
import { omit, get } from 'lodash';
import { toast } from 'react-toastify';

import { SEND_CHECKOUT } from '../../../../graphql/queries/order';
import { SEND_APPLICATION } from '../../../../graphql/queries/customer';

import FormInput from '../../../../components/FormInput';
import FormTextArea from '../../../../components/FormTextArea';
import Button from '../../../../components/Button';
import CustomNotification from '../../../../components/CustomNotification';

const SendCheckoutLinkFormSchema = yup.object().shape({
  firstName: yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('This field is required'),
  lastName: yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('This field is required'),
  phone: yup.string()
    .required('This field is required'),
  email: yup.string()
    .typeError('Invalid email')
    .email('Invalid email')
    .required('This field is required'),
  message: yup.string(),
});

function SendCheckoutLinkForm({
  companyNumber,
  crmOpportunityId,
  orderNumber,
  isApplication,
  disabled,
}) {
  const [sendCheckout, { loading: checkoutSending }] = useMutation(SEND_CHECKOUT);
  const [sendApplicationMutation] = useMutation(SEND_APPLICATION);

  const header = useMemo(() => {
    return (
      <div>
        {isApplication 
          ? (
            <div className="space-y-6">
              <h1 className="sf-h1 text-sf-vartana-black">Send application to collect documents</h1> 
              <p className="text-sf-vartana-black sf-p-small">Tell us who and where to send the application.</p>
            </div>
          )
          : <h1 className="sf-h1 text-sf-vartana-black">Resend or forward checkout link to a new signer</h1> }
      </div>
    );
  }, [isApplication]);

  return (
    <>
      {header}

      <Formik
        initialValues={{
          validateOnMount: true,
          companyNumber,
          crmOpportunityId,
          number: orderNumber,
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          message: '',
        }}
        validationSchema={SendCheckoutLinkFormSchema}
        onSubmit={async (values, { setErrors }) => {
          try {
            const responseData = isApplication 
              ? await sendApplicationMutation({
                variables: {
                  ...omit(values, ['validateOnMount', 'number', 'crmOpportunityId']),
                },
              })
              : await sendCheckout({ 
                variables: {
                  ...omit(values, ['validateOnMount']),
                },
              });

            const errors = get(responseData, 'errors', {});

            if (Object.keys(errors).length) {
              setErrors(errors);
            } else {
              let message = 'Checkout link sent!';
              if (isApplication) message = 'Application Sent! Customer will receive their application shortly. Contact us if you have any questions!';
              toast.success(({ toastProps }) => {
                return (
                  <CustomNotification 
                    type={toastProps.type} 
                    message={message}
                  />
                );
              });
            }
          } catch (e) {
            console.log(e);
          }
        }}
      >
        {({
          isSubmitting, 
          isValid, 
          dirty,
        }) => {
          const submitDisabled = disabled || isSubmitting || checkoutSending || !(isValid && dirty);

          return (
            <Form className="mt-4">
              <fieldset disabled={disabled}>
                <FormInput
                  className="tw-hidden"
                  name="companyNumber"
                  type="hidden"
                />
                <FormInput
                  className="tw-hidden"
                  name="crmOpportunityId"
                  type="hidden"
                />
                <FormInput
                  className="tw-hidden"
                  name="number"
                  type="hidden"
                />
                <div className="space-y-4">
                  <div className="flex flex-row space-x-4">
                    <FormInput
                      name="firstName"
                      label="Legal first name"
                      type="text"
                      className="flex-1"
                      disabled={disabled}
                    />

                    <FormInput
                      name="lastName"
                      label="Legal last name"
                      type="text"
                      className="flex-1"
                      disabled={disabled}
                    />
                  </div>

                  <div className="flex flex-row space-x-4">
                    <FormInput
                      name="email"
                      label="Signer's email"
                      type="text"
                      className="flex-1"
                      disabled={disabled}
                    />

                    <FormInput
                      name="phone"
                      placeholder="+1 (###) ###-####"
                      label="Phone"
                      type="text"
                      mask="+1 (999) 999-9999"
                      disableFullstoryRecording
                      className="flex-1"
                      disabled={disabled}
                    /> 
                  </div>
                  <div className="flex flex-row">
                    <FormTextArea
                      name="message"
                      label="Include a message"
                      placeholder="Hello..."
                      className="flex-1"
                      disabled={disabled}
                    />
                  </div>
                </div>

                <div className="flex flex-row justify-center mt-6">
                  <Button
                    type="submit"
                    disabled={submitDisabled}
                    element={(
                      <p className={`sf-button-text text-white ${submitDisabled ? 'bg-sf-vartana-gray-40' : 'bg-sf-blue'} px-24 py-2 rounded`}>Submit</p>
                    )}
                  />
                </div>
              </fieldset>
            </Form>
          );
        }}
      </Formik>

    </>
  );
}

SendCheckoutLinkForm.propTypes = {
  companyNumber: PropTypes.string.isRequired,
  crmOpportunityId: PropTypes.string,
  orderNumber: PropTypes.string,
  disabled: PropTypes.bool,
  isApplication: PropTypes.bool,
};

SendCheckoutLinkForm.defaultProps = {
  crmOpportunityId: '',
  orderNumber: '',
  disabled: false,
  isApplication: false,
};

export default SendCheckoutLinkForm;
