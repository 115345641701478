import { gql } from '@apollo/client';

export const GET_CURRENT_SESSION = gql`
  query {
    session {
      id
      user {
        id
        firstName
        lastName
        email
        createdAt
      }
    }
    seller {
      name
      logoUrl
      product {
        allowedDurations
        useRequestedAmount
      }
    }
  }
`;
