/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, {
  useCallback,
  useState,
  useMemo,
  useEffect,
} from 'react';

import {
  useFormik,
  FormikProvider,
} from 'formik';

import PropTypes from 'prop-types';

import * as yup from 'yup';
import { toast } from 'react-toastify';

import {
  useHistory,
} from 'react-router-dom';

import {
  omit, get, mapKeys, isEmpty, capitalize,
} from 'lodash';

import { useMutation } from '@apollo/client';

import { useBeforeunload } from 'react-beforeunload';
import { useDirectUploadFiles } from '../../../../utils/hooks';
import FormInput from '../../../../components/FormInput';
import ButtonToggle from '../../../../components/ButtonToggle';
import AutoLoad from '../../../../components/AutoLoad';
import FormTextArea from '../../../../components/FormTextArea';
import MultiFileUpload from '../../../../components/MultiFileUpload';
import FormReadOnlyInput from '../../../../components/FormReadOnlyInput';
import slashLine from '../../../../assets/slash_line.svg';
import CustomNotification from '../../../../components/CustomNotification';
import MultiSelectButton from '../../../../components/MultiSelectButton';
import {
  CREATE_CREDIT_APPRAISAL_CHANGE_REQUEST,
} from '../../../../graphql/queries/creditAppraisalChangeRequest';


const baseSchema = {
  message: yup.string().required('This field is required'),
  increaseThresholdAmount: yup.boolean()
    .oneOf([true, false], 'This field is required'),
  increaseTerm: yup.boolean()
    .oneOf([true, false], 'This field is required'),
  amount: yup.number()
    .positive()
    .nullable()
    .when('increaseThresholdAmount', {
      is: true,
      then: yup.number().min(250_000.01, 'Enter an amount above $250,000.'),
      otherwise: yup.number().max(250_000, 'Enter an amount upto $250,000.'),
    }),
  term: yup.number()
    .min(12, 'Please input a term between 12 and 60 months.')
    .max(60, 'Please input a term between 12 and 60 months.')
    .integer('Please input an integer.')
    .nullable(),
  billingFrequencies: yup.array()
    .nullable(),
  paymentTerms: yup.array()
    .nullable(),
};

const uploadDocumentsSchema = {
  financialDocuments: yup.array().required('Financial Document is required'),
  bankStatements: yup.array().required('Bank Statement is required'),
};

const sendCheckoutLinkSchema = {
  firstName: yup.string()
    .min(1, 'Too Short!')
    .max(50, 'Too Long!')
    .required('This field is required'),
  lastName: yup.string()
    .min(1, 'Too Short!')
    .max(50, 'Too Long!')
    .required('This field is required'),
  email: yup.string()
    .typeError('Invalid email')
    .email('Invalid email')
    .required('Email is required'),
  phone: yup.string().required('This field is required'),
  companyMessage: yup.string().required('Please include a message'),
};

function RequestHigherLimitForm({
  companyName,
  availableFrequencies,
  availablePaymentTerms,
}) {
  const history = useHistory();
  const queryParams = history.location.search;
  const useQueryParams = new URLSearchParams(queryParams);
  const customerNumber = useQueryParams.get('customerNumber') || '';
  
  const [canSafelyExit, setCanSafelyExit] = useState(true);
  const [formSchema, setFormSchema] = useState(baseSchema);
  const [directUploadFiles] = useDirectUploadFiles();
  
  const [createCreditAppraisalChangeRequest] = useMutation(CREATE_CREDIT_APPRAISAL_CHANGE_REQUEST);
  
  const onSubmit = useCallback(async (values, { setErrors }) => {
    try {
      const {
        bankStatements,
        financialDocuments,
        uploadDocuments,
        increaseThresholdAmount,
        increaseTerm,
        amount,
        term,
        billingFrequencies,
        paymentTerms,
      } = values;
      
      let cleanedValues = omit(values, [
        'uploadDocuments',
        'bankStatements',
        'financialDocuments',
        'companyName',
      ]);
      
      if (!increaseTerm) {
        cleanedValues = omit(cleanedValues, ['term']);
      }
      
      if (!increaseThresholdAmount || uploadDocuments) {
        cleanedValues = omit(cleanedValues, [
          'firstName',
          'lastName',
          'email',
          'phone',
          'companyMessage',
        ]);
      }
      
      if (!amount) {
        cleanedValues = {
          ...cleanedValues,
          amount: 0,
        };
      }
      
      if (!term) {
        cleanedValues = {
          ...cleanedValues,
          term: 0,
        };
      }
  
      if (!billingFrequencies) cleanedValues = omit(cleanedValues, 'billingFrequencies');
      if (!paymentTerms) cleanedValues = omit(cleanedValues, 'paymentTerms');
      
      const responseData = await createCreditAppraisalChangeRequest({
        variables: {
          ...cleanedValues,
        },
      });
      
      const newCreditAppraisalId = get(responseData, 'data.createCreditAppraisalChangeRequest.id', '');
      const errors = get(responseData, 'errors', {});
      
      let successMessage = 'Upon review, new terms will be generated.';
      const errorsCleaned = mapKeys(errors, (_, key) => {
        if (key === 'creditAppraisalChangeRequestTerm') return 'term';
        if (key === 'creditAppraisalChangeRequestAmount') return 'amount';
        if (key === 'creditAppraisalChangeRequestBillingFrequencies') return 'billingFrequencies';
        if (key === 'creditAppraisalChangeRequestPaymentTerms') return 'paymentTerms';
        return key;
      });
      
      if (Object.keys(errorsCleaned).length) {
        setErrors(errorsCleaned);
      } else {
        if (uploadDocuments) {
          await directUploadFiles([...bankStatements], {
            documentType: 'bank_statement',
          }, {
            id: newCreditAppraisalId,
            type: 'CreditAppraisal',
          });
          
          await directUploadFiles([...financialDocuments], {
            documentType: 'tax_return',
          }, {
            id: newCreditAppraisalId,
            type: 'CreditAppraisal',
          });
        }
        
        if (increaseThresholdAmount && !uploadDocuments) { // send application
          successMessage = 'New terms will be generated upon receiving financial documents. Visit “Track application” tab for updates.';
        }
        
        setCanSafelyExit(true);
        
        setTimeout(() => {
          toast.success(({ toastProps }) => {
            return (
              <CustomNotification
                type={toastProps.type}
                message={successMessage}
              />
            );
          });
          history.push(`/forms/help${queryParams}&help=true`);
        }, 0);
      }
    } catch (e) {
      console.log(e);
    }
  }, [
    history,
    setCanSafelyExit,
    createCreditAppraisalChangeRequest,
    directUploadFiles,
    queryParams,
  ]);
  
  const formikBag = useFormik({
    initialValues: {
      companyNumber: customerNumber,
      companyName,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      companyMessage: '',
      increaseThresholdAmount: null,
      increaseTerm: null,
      uploadDocuments: false,
      message: '',
      amount: '',
      term: '',
      billingFrequencies: [],
      paymentTerms: [],
      financialDocuments: undefined,
      bankStatements: undefined,
    },
    enableReinitialize: true,
    validationSchema: formSchema,
    onSubmit,
  });
  
  const {
    isSubmitting,
    isValid,
    dirty,
    values: {
      increaseThresholdAmount,
      increaseTerm,
      uploadDocuments,
    },
    touched,
    handleSubmit,
  } = formikBag;
  
  const disabled = isSubmitting || !(isValid && dirty);
  
  useBeforeunload((event) => {
    if (!canSafelyExit) {
      event.preventDefault();
    }
  });
  
  useEffect(() => {
    let newSchema = { ...baseSchema };
    
    if (increaseThresholdAmount) {
      if (uploadDocuments) newSchema = Object.assign(newSchema, uploadDocumentsSchema);
      else newSchema = Object.assign(newSchema, sendCheckoutLinkSchema);
    }
    
    setFormSchema(yup.object().shape({
      ...newSchema,
    }));
  }, [uploadDocuments, increaseThresholdAmount, setFormSchema]);
  
  useEffect(() => {
    setCanSafelyExit(!dirty || Object.keys(touched).length === 0);
  }, [touched, dirty]);
  
  const uploadDocumentsForm = useMemo(() => {
    if (uploadDocuments) {
      return (
        <>
          <MultiFileUpload name="financialDocuments" label="Financial documents (2 years)" />
          <MultiFileUpload name="bankStatements" label="Bank statements (3 months)" />
        </>
      );
    }
    return (
      <>
        <FormReadOnlyInput
          name="companyName"
          label="Company Name"
          className="flex-1"
          disabled
        />
        
        <div className="flex flex-row space-x-4">
          <FormInput
            name="firstName"
            label="Legal first name"
            type="text"
            className="flex-1"
          />
          
          <FormInput
            name="lastName"
            label="Legal last name"
            type="text"
            className="flex-1"
          />
        </div>
        
        <div className="flex flex-row space-x-4">
          <FormInput
            name="email"
            label="Signer's email"
            type="text"
            className="flex-1"
          />
          
          <FormInput
            name="phone"
            placeholder="+1 (###) ###-####"
            label="Phone"
            type="text"
            mask="+1 (999) 999-9999"
            disableFullstoryRecording
            className="flex-1"
          />
        </div>
        
        <div className="flex flex-row">
          <FormTextArea
            name="companyMessage"
            label="Include a message"
            placeholder="Hello..."
            className="flex-1"
          />
        </div>
      </>
    );
  }, [uploadDocuments]);
  
  const additionalTermOptions = useMemo(() => {
    if (isEmpty(availableFrequencies) && isEmpty(availablePaymentTerms)) return null;
    
    const formattedPaymentTerms = availablePaymentTerms.map((term) => (
      term ? [`Net-${term}`, term] : ['Upon receipt', term]
    ));
    
    return (
      <div className="space-y-6 pb-8 mb-2">
        <h1 className="sf-card-title text-sf-vartana-black mt-8">Additional term options</h1>
        {availableFrequencies.length ? (
          <div>
            <MultiSelectButton
              name="billingFrequencies"
              label="Add frequencies"
              options={availableFrequencies.map((frequency) => ({
                label: capitalize(frequency),
                value: frequency,
              }))}
            />
          </div>
        ) : null}
        {availablePaymentTerms.length ? (
          <div>
            <MultiSelectButton
              name="paymentTerms"
              label="Add payment terms"
              options={formattedPaymentTerms.map(([label, value]) => ({
                label,
                value,
              }))}
            />
          </div>
        ) : null}
      </div>
    );
  }, [availableFrequencies, availablePaymentTerms]);
  
  return (
    <>
      <h1 className="sf-card-title text-sf-vartana-black">Request to modify terms</h1>
      <p className="sf-body text-sf-vartana-black">Increase higher offer limits or add additional term options.</p>
      <FormikProvider value={formikBag}>
        <form
          className="mt-4"
          onSubmit={handleSubmit}
        >
          <fieldset disabled={isSubmitting}>
            <div className="grid grid-cols-1 divide-sf-vartana-gray-30">
              <div className="space-y-3 pb-8">
                <h2 className="sf-card-subtitle text-sf-vartana-black">
                  Increase higher offer limits
                </h2>
                <FormInput
                  className="hidden"
                  name="companyNumber"
                  type="hidden"
                />
                <div>
                  <ButtonToggle
                    name="increaseThresholdAmount"
                    label="Amount needed across all plans:"
                    buttonProps={[{
                      label: 'Under $250,000',
                      value: false,
                    }, { label: 'Over $250,000', value: true }]}
                  />
                </div>
                <div className="pt-2">
                  <ButtonToggle
                    name="increaseTerm"
                    label="Maximum term across all plans:"
                    buttonProps={[{
                      label: 'Within approved options',
                      value: false,
                    }, { label: 'Beyond approved options', value: true }]}
                  />
                </div>
                <div className="flex flex-row space-x-4 pt-2">
                  <FormInput
                    id="amount"
                    name="amount"
                    type="number"
                    label="Specific amount (optional)"
                    className="flex-1"
                    prefix="$"
                    onChange={(e, { setValue }) => {
                      const { value } = e.target;
                      if (value) {
                        setValue(Math.abs(value));
                      } else {
                        setValue(value);
                      }
                    }}
                  />
                  {
                    increaseTerm ? (
                      <>
                        <img className="self-center" alt="slash" src={slashLine} />
                        <FormInput
                          id="term"
                          name="term"
                          type="number"
                          label="Specific term (optional)"
                          className="flex-1"
                          onChange={(e, { setValue }) => {
                            const { value } = e.target;
                            if (value) {
                              setValue(Math.abs(e.target.value));
                            } else {
                              setValue(e.target.value);
                            }
                          }}
                        />
                      </>
                    ) : null
                  }
                </div>
                <div className="flex flex-row">
                  <FormTextArea
                    name="message"
                    label="Supporting details"
                    className="flex-1"
                    tooltipElement={(
                      <p>
                        Include specifics about the customer or <br />
                        potential order. For example, “Customer <br />
                        is renewing 40 units and adding 10 units <br />
                        to support future growth”
                      </p>
                    )}
                  />
                </div>
                
                {
                  increaseThresholdAmount ? (
                    <>
                      <div className="space-y-4">
                        <div className="sf-body text-sf-vartana-gray-60 space-y-2">
                          <p>Additional documents are needed for requests over $250,000:</p>
                          <ul className="list-disc ml-8">
                            <li>2 years of financial documents</li>
                            <li>3 months of bank statements</li>
                          </ul>
                        </div>
                      </div>
                      
                      <div>
                        <ButtonToggle
                          name="uploadDocuments"
                          buttonProps={[{
                            label: 'Upload documents',
                            value: true,
                          }, { label: 'Send application to customer', value: false }]}
                        />
                      </div>
                      
                      {uploadDocumentsForm}
                    </>
                  ) : null
                }
                {additionalTermOptions}
              </div>
            </div>
            
            <AutoLoad loading={isSubmitting}>
              <div className="flex flex-row-reverse items-center space-x-reverse space-x-8">
                <div>
                  <button
                    type="submit"
                    className={`py-4 px-12 ${disabled ? 'bg-sf-vartana-gray-40' : 'bg-sf-vartana-blue-60'} rounded-md`}
                    disabled={disabled}
                  >
                    <p className="sf-btn-text text-white">Submit</p>
                  </button>
                </div>
                <div>
                  <a href={`/crm/widget${queryParams}`}>
                    <p className="sf-text-link text-sf-vartana-blue-60">Cancel and exit</p>
                  </a>
                </div>
              </div>
            </AutoLoad>
          </fieldset>
        </form>
      </FormikProvider>
    </>
  );
}

RequestHigherLimitForm.propTypes = {
  companyName: PropTypes.string.isRequired,
  availableFrequencies: PropTypes.arrayOf(PropTypes.string),
  availablePaymentTerms: PropTypes.arrayOf(PropTypes.number),
};

RequestHigherLimitForm.defaultProps = {
  availableFrequencies: [],
  availablePaymentTerms: [],
};

export default RequestHigherLimitForm;
