/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */

import React, { 
  forwardRef,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import FormFieldMsg from '../FormFieldMsg';
import FormLabel from '../FormLabel';
import calendar from '../../assets/calendar.svg';

const CustomInput = forwardRef(({ className, ...props }, ref) => {
  return (
    <>
      <input
        ref={ref}
        type="text"
        className={`${className} `}
        {...props}
      />
      <div role="button" tabIndex="0" onClick={props.onClick}>
        <img
          alt="calendar" 
          src={calendar}
          className="absolute right-0 top-2/4 transform-gpu -translate-y-2/4 px-3"
        />
      </div>
    </>
  );
});

function DateInput({ 
  name,
  placeholder,
  highlightDates,
  label,
  className,
  onChange,
}) {
  const [field, meta, helpers] = useField(name);

  const [currentDate, setCurrentDate] = useState(() => {
    if (field?.value) {
      return moment(field.value, 'YYYY-MM-DDTHH:mm:ssZ').toDate();
    }
    return null;
  });

  const styleClasses = useMemo(() => ({
    default: {
      borders: 'border-sf-vartana-gray-40',
      text: 'placeholder-sf-vartana-gray-40',
      label: 'text-sf-vartana-gray-60',
    },
    error: {
      borders: 'border-red-400 focus:outline-none focus:ring-0 focus:border-current',
      text: 'text-red-400 placeholder-red-400',
      label: 'text-red-400',
    },
  }), []);

  const formStateClasses = useMemo(() => {
    let classes = styleClasses.default;
    if (meta.touched && meta.error) {
      classes = styleClasses.error;
    }
    return classes;
  }, [styleClasses, meta]);

  return (
    <div className={`${className}`}>
      <FormLabel containerClassName="mb-2" labelClassName={`${formStateClasses.label}`} name={field.name} label={label} />
      <DatePicker
        className={`
          border 
          border-solid 
          rounded
          py-2
          w-full
          text-sm
          ${formStateClasses.borders}
          ${formStateClasses.text}
        `}
        name={field.name}
        id={field.name}
        calendarClassName="rasta-stripes"
        selected={currentDate}
        onChange={(date) => {
          if (!date) {
            helpers.setValue('');
            setCurrentDate(null);
            return;
          }
          const dateString = moment(date || '').format('YYYY-MM-DD');
          setCurrentDate(date);
          helpers.setValue(dateString || '');
          onChange(dateString);
        }}
        placeholderText={placeholder}
        autoComplete="off"
        customInput={<CustomInput />}
        onChangeRaw={() => helpers.setTouched(true, true)}
        highlightDates={highlightDates}
      />
      <FormFieldMsg show={meta.touched} msg={meta.error} className={`${formStateClasses.text} mt-4`} />
    </div>
  );
}


DateInput.propTypes = {
  name: PropTypes.string,
  highlightDates: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.arrayOf(PropTypes.date),
    }), 
  ),
  placeholder: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
};

DateInput.defaultProps = {
  name: '',
  highlightDates: [],
  placeholder: '',
  label: '',
  className: '',
  onChange: () => {},
};

export default DateInput;
