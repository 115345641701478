import React, { useState, useEffect } from 'react';

import { 
  Route, 
  Switch,
  useLocation,
} from 'react-router-dom';

import { get } from 'lodash';

import { useLazyQuery } from '@apollo/client';

import { useIntercom } from 'react-use-intercom';
import * as FullStory from '@fullstory/browser';

import { GET_CURRENT_SESSION } from './graphql/queries/session';
import { sessionVar, requestModelAmountVar } from './graphql/cache';
import { useSetCookie } from './utils/hooks';

import ErrorBoundary from './components/ErrorBoundary';
import ToastNotification from './components/ToastNotification';
import Error from './pages/Error';

import Forms from './pages/CRM/Forms';
import CRM from './pages/CRM';
import AutoLoad from './components/AutoLoad';

FullStory.init({
  orgId: '129FQB',
  devMode: process.env.REACT_APP_NODE_ENV === 'staging' || process.env.REACT_APP_NODE_ENV === 'preprod' || process.env.REACT_APP_NODE_ENV === 'development',
});

function RootComponent() {
  const [isLoading, setLoading] = useState(true);
  const { search, pathname } = useLocation();
  const useQueryParams = new URLSearchParams(search);
  const showIntercomWindow = useQueryParams.get('help') === 'true';
  const token = useQueryParams.get('t');
  const application = useQueryParams.get('application');
  const { show: showIntercom, boot: bootIntercom } = useIntercom();
  const [setCookie] = useSetCookie();

  const [getSessionData, {
    data: sessionData,
  }] = useLazyQuery(GET_CURRENT_SESSION, { 
    onCompleted: () => {
      sessionVar(sessionData);
      requestModelAmountVar(get(sessionData, 'seller.product.useRequestedAmount', false));
      setLoading(false);
    },
  });

  useEffect(() => {
    async function onMount() {
      FullStory.getCurrentSessionURL();
      if (token) await setCookie(token);
      try {
        await getSessionData();
      } catch (e) {
        console.log(e);
      }
    }
    onMount();
  }, [token, getSessionData, setCookie]);

  useEffect(() => {
    const user = get(sessionData, 'session.user', null);
    const shouldBootIntercom = !pathname.includes('/crm/widget');
    if (user) {
      const fullName = `${user.firstName} ${user.lastName}`;
      FullStory.identify(user.email, {
        customer: fullName,
        displayName: fullName,
        email: user.email,
      });
      if (shouldBootIntercom) {
        bootIntercom({
          name: application === 'hubspot' ? '' : fullName,
          email: user.email,
          created_at: new Date(user.createdAt).valueOf(),
          customAttributes: {
            application: 'widget-app',
            environment: process.env.REACT_APP_NODE_ENV,
            resourceId: user.id,
            resourceType: 'User',
          },
        });
        if (showIntercomWindow) showIntercom();
      }
    }
  }, [pathname, sessionData, showIntercomWindow, showIntercom, bootIntercom, application]);

  return (
    <ErrorBoundary>
      <ToastNotification />
      <AutoLoad
        loading={isLoading}
        containerClassName="flex justify-center"
        className="absolute text-center top-2/4 transform-gpu -translate-y-2/4"
      >
        <div className="font-sans min-h-screen bg-white sm:bg-main-bg">
          <Switch>
            <Route path="/forms">
              <Forms seller={get(sessionData, 'seller')} />
            </Route>
            <Route path="/crm" component={CRM} />
            <Route render={() => <Error errorTitle="404" errorMessage="Page not found" />} />
          </Switch>
        </div>
      </AutoLoad>
    </ErrorBoundary>
  );
}
  
export default RootComponent;
