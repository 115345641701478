import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useField } from 'formik';
import FormLabel from '../FormLabel';
import FormFieldMsg from '../FormFieldMsg';

import 'react-step-progress-bar/styles.css';

function MultiSelectButton({
  name, 
  className,
  tooltipElement,
  label,
  options,
}) {
  const [field, { touched, error }, { setValue }] = useField({
    name,
    type: 'select',
    multiple: true,
  });

  const styleClasses = useMemo(() => ({
    default: {
      text: 'placeholder-sf-vartana-gray-40',
      label: 'text-sf-vartana-gray-60',
    },
    error: {
      text: 'text-sf-vartana-dark-red placeholder-sf-vartana-dark-red',
      label: 'text-sf-vartana-dark-red',
    },
  }), []);

  const formStateClasses = (touched && error) ? styleClasses.error : styleClasses.default;

  const onSelect = (newValue) => {
    let list = field.value || [];
    const valueExists = list.includes(newValue);

    if (valueExists) list = list.filter((val) => val !== newValue);
    else list = [...list, newValue];

    setValue(list);
  };

  const isSelected = (value) => {
    const list = field.value || [];
    return list.includes(value);
  };

  return (
    <>
      <div className={`${className}`}>
        <FormLabel containerClassName="mb-2" labelClassName={`${formStateClasses.label}`} name={field.name} label={label} tooltipElement={tooltipElement} />
        <div className="flex flex-row space-x-4">
          {options.length > 0 ? options.map(({ id, label: optionLabel, value }) => (
            <div key={id} className="flex-1">
              <button
                type="button" 
                className={`
                  w-full rounded-full focus:outline-none text-center p-3 sf-text-link-bold
                  ${isSelected(value) ? 'bg-sf-vartana-blue-40 text-white' : 'bg-sf-light-blue-20 text-sf-vartana-blue-80'}
                `}
                onClick={() => onSelect(value)}
              >
                <p className="break-all">{optionLabel}</p>
              </button>
            </div>
          )) : 'No values found'}
        </div>
      </div>
      <FormFieldMsg show={touched} msg={error} className={`${formStateClasses.text} mt-2`} />
    </>
  );
}

MultiSelectButton.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  tooltipElement: PropTypes.elementType,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
    value: PropTypes.string,
  })),
};

MultiSelectButton.defaultProps = {
  name: '',
  className: '',
  tooltipElement: null,
  label: '',
  options: [{
    id: 0,
    label: '2',
    value: '2',
  },
  {
    id: 1,
    label: '1',
    value: '1',
  }],
};

export default MultiSelectButton;
