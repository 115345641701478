import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { IntercomProvider } from 'react-use-intercom';
import { Router as BrowserRouter } from 'react-router-dom';
import Modal from 'react-modal';


import * as serviceWorker from './serviceWorker';
import history from './utils/browser_history';
import client from './graphql';
import RootComponent from './root';

import './index.scss';

Modal.setAppElement('#root');

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter history={history}>
      <ApolloProvider client={client}>
        <IntercomProvider appId={process.env.REACT_APP_INTERCOM_ID}>
          <RootComponent />
        </IntercomProvider>
      </ApolloProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
