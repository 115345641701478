/* eslint-disable object-shorthand */

import React, { useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { difference, get } from 'lodash';

import { useQuery, useReactiveVar } from '@apollo/client';
import { useIntercom } from 'react-use-intercom';
import * as FullStory from '@fullstory/browser';

import { GET_APPROVED_OFFERS } from '../../../../graphql/queries/offers';
import { GET_CUSTOMER_BY_NUMBER } from '../../../../graphql/queries/customer';
import { sessionVar } from '../../../../graphql/cache';
import AutoLoad from '../../../../components/AutoLoad';
import ApprovedPaymentPlans from '../../page_components/ApprovedPaymentPlans';
import { formatCapital } from '../../../../utils/helpers';
import RequestHigherLimitForm from './form';

function OrderEdit() {
  const location = useLocation();
  const useQueryParams = new URLSearchParams(location.search);
  const companyNumber = useQueryParams.get('customerNumber') || '';
  const application = useQueryParams.get('application');
  const { update: updateIntercom } = useIntercom();
  
  const {
    data: offersData,
    loading: offersLoading,
  } = useQuery(GET_APPROVED_OFFERS, {
    variables: {
      number: companyNumber,
    },
  });
  
  const {
    data: customerData,
    loading: customerLoading,
  } = useQuery(GET_CUSTOMER_BY_NUMBER, {
    variables: {
      number: companyNumber,
    },
  });
  
  const { availableFrequencies, availablePaymentTerms } = useMemo(() => {
    const allFrequencies = get(customerData, 'company.seller.product.allowedPaymentFrequencies', []);
    const allPaymentTerms = get(customerData, 'company.seller.product.allowedPaymentTerms', []);
    
    const currentFrequencies = get(offersData, 'company.creditAppraisal.allowedBillingFrequencies', []);
    const currentPaymentTerms = get(offersData, 'company.creditAppraisal.allowedPaymentTerms', []);
    
    return {
      availableFrequencies: difference(allFrequencies, currentFrequencies),
      availablePaymentTerms: difference(allPaymentTerms, currentPaymentTerms),
    };
  }, [customerData, offersData]);
  
  const offers = useMemo(
    () => get(offersData, 'company.creditAppraisal.approvedCreditTermsFormatted', []),
    [offersData],
  );
  
  const approvedOffers = useMemo(() => {
    if (offers.length && customerData) {
      return (
        <div className="bg-white p-6">
          <h1 className="sf-card-title text-center text-sf-vartana-black">Approved payment plans</h1>
          <ApprovedPaymentPlans
            offers={offers}
            formattedMinAllowedOrderAmount={get(customerData, 'company.formattedMinAllowedOrderAmount', '$0')}
            pg={get(customerData, 'company.creditAppraisal.pgRequired', false)}
          />
        </div>
      );
    }
    return null;
  }, [offers, customerData]);

  const sessionData = useReactiveVar(sessionVar);

  useEffect(() => {
    const user = get(sessionData, 'session.user', null);
    const companyId = get(customerData, 'company.id', null);
    const companyUrlAdmin = !companyId ? '' : `${process.env.REACT_APP_ADMIN_URL}/admin/companies/${companyId}`;
    if (user) {
      const fullName = `${user.firstName} ${user.lastName}`;
      FullStory.identify(user.email, {
        customer: fullName,
        displayName: fullName,
        email: user.email,
      });
      updateIntercom({
        name: application === 'hubspot' ? '' : fullName,
        email: user.email,
        created_at: new Date(user.createdAt).valueOf(),
        customAttributes: {
          application: 'widget-app',
          environment: process.env.REACT_APP_NODE_ENV,
          resourceId: user.id,
          resourceType: 'User',
          companyUrlAdmin,
        },
      });
    }
  }, [sessionData, customerData, updateIntercom, application]);
  
  return (
    <div className="flex flex-col">
      <AutoLoad
        loading={offersLoading || customerLoading}
        containerClassName="flex justify-center"
        className="absolute text-center top-2/4 transform-gpu -translate-y-2/4"
      >
        <div className="p-1 grid grid-cols-2 gap-x-2 divide-x divide-solid">
          <div className="bg-white mx-3">
            <RequestHigherLimitForm
              companyName={formatCapital(get(customerData, 'company.name', ''))}
              availableFrequencies={availableFrequencies}
              availablePaymentTerms={availablePaymentTerms}
            />
          </div>
          <div className="grid grid-flow-row auto-rows-max gap-y-4">
            {approvedOffers}
          </div>
        </div>
      </AutoLoad>
    </div>
  );
}

export default OrderEdit;
