import React from 'react';
import ReactDOMServer from 'react-dom/server';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';

import tooltipIcon from '../../assets/tooltip.svg';

function DangerousTooltip({ element }) {
  return (
    element ? (
      <>
        <img 
          alt="tooltip" 
          src={tooltipIcon} 
          data-tip={ReactDOMServer.renderToString(
            <div className="sf-p-small p-2">
              {element}
            </div>,
          )}
        />
        <ReactTooltip 
          effect="solid" 
          place="top" 
          backgroundColor="#333333"
          data-padding="8px 8px"
          html
        />
      </>
    ) : null
  );
}

DangerousTooltip.propTypes = {
  element: PropTypes.elementType.isRequired,
};

export default DangerousTooltip;
