import React, { useState, useMemo } from 'react';
import { 
  Route, 
  Switch, 
  Redirect,
  useRouteMatch,
} from 'react-router-dom';

import Widget from './Widget';
import Token from '../../api/token/index';

import { ConfettiContext } from '../../utils/contexts';

function CRM() {
  const { path: currentPath } = useRouteMatch();

  const [confettiCounter, setConfettiCounter] = useState(0);

  const confettiContext = useMemo(() => ({ // needs to be constant across rerenders
    confettiCounter,
    setConfettiCounter,
  }), [confettiCounter, setConfettiCounter]);

  return (
    <ConfettiContext.Provider value={confettiContext}>
      <div className="bg-white rounded-lg">
        <Switch>
          <Route path={`${currentPath}/widget`} component={Widget} />
          <Route path={`${currentPath}/connect`} component={Token} />
          <Redirect to="/" />
        </Switch>
      </div>
    </ConfettiContext.Provider>
  );
}

export default CRM;
