import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

function CustomNotification({
  type,
  message,
}) {
  const icon = useMemo(() => {
    switch (type) {
    case 'success': {
      return (
        <svg className="h-5 w-5 text-sf-vartana-green" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="9.9999" cy="9.99999" r="9.6" fill="currentColor" />
          <path d="M5.19971 9.8125L8.48948 13L14.4895 7" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
        </svg>
      );
    }
    default: {
      return (
        <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
        </svg>
      );
    }
    }
  }, [type]);

  return (
    <div className="flex space-x-2">
      <div className="flex-shrink-0">
        {icon}
      </div>
      <div>
        <p className="sf-p-small text-sf-vartana-black">
          {message}
        </p>
      </div>
    </div>
  );
}

CustomNotification.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default CustomNotification;
