import React from 'react';
import PropTypes from 'prop-types';

function Loader({ 
  className,
  containerClassName,
}) {
  return (
    <div className={containerClassName}>
      <div className={`w-12 h-12 border-2 block lds-dual-ring m-auto ${className}`} />
    </div>
  );
}

Loader.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
};

Loader.defaultProps = {
  className: '',
  containerClassName: '',
};

export default Loader; 
