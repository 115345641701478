import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import Button from '../Button';

import crossIcon from '../../assets/cross.svg';

function CloseConfirmation({
  open,
  onConfirm,
  onClose,
}) {
  return (
    <Modal isOpen={open} className="modal">
      <div className="absolute top-1/4 left-1/2 transform-gpu -translate-x-2/4">
        <div className="rounded-lg bg-white py-4 px-6">
          <div className="flex flex-row-reverse">
            <button className="cursor-pointer" onClick={onClose}>
              <img alt="close" src={crossIcon} />
            </button>
          </div>
          <div className="space-y-1">
            <h1 className="sf-h1 inline-block">Are you sure?</h1> 
            <p className="sf-body text-sf-vartana-black">Information will not be saved if you exit the window.</p>
          </div>
          <div className="flex flex-row-reverse mt-8">
            <div className="flex flex-row justify-center">
              <button onClick={onClose}>
                <p className="sf-button-text text-sf-blue px-4 py-2">Cancel</p>
              </button>
              <Button
                element={(
                  <p className="sf-button-text text-white bg-sf-blue rounded px-4 py-2">Exit</p>
                )}
                onClick={onConfirm}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

CloseConfirmation.propTypes = {
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
};

CloseConfirmation.defaultProps = {
  open: false,
  onConfirm: () => {},
  onClose: () => {},
};

export default CloseConfirmation;
