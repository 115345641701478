/* eslint-disable import/prefer-default-export */

import { useLocation } from 'react-router-dom';
import { 
  useCallback, 
  useEffect, 
  useLayoutEffect, 
  useState,
} from 'react';
import { get } from 'lodash';
import { 
  useMutation,
} from '@apollo/client';

import { 
  CREATE_DIRECT_UPLOADS, 
  ATTACH_BLOBS_RESOURCE,
} from '../../graphql/queries/directUpload';

import { calculateFilesMetadata } from '../helpers';
import { performUpload } from '../../api/directUpload';
import { fetchCookie } from '../../api/token';

export const useQueryParams = () => new URLSearchParams(useLocation().search);

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};

export const useDirectUploadFiles = () => {
  const [createDirectUploads] = useMutation(CREATE_DIRECT_UPLOADS);
  const [attachBlobsResource] = useMutation(ATTACH_BLOBS_RESOURCE);

  return [function directUploadFiles(files, documentBlobs, resource) {
    return new Promise((resolve, reject) => {
      calculateFilesMetadata(files).then(((filesMetadata) => {
        createDirectUploads({ 
          variables: {
            directUploads: filesMetadata,
          },
        }).then((responseData) => {
          const directUploads = get(responseData, 'data.createDirectUploads.directUploads');
          const pendingUploads = directUploads.map((directUpload, index) => {
            return performUpload(files[index], JSON.parse(directUpload.headers), directUpload.url);
          });
          Promise.all(pendingUploads).then(() => {
            const merged = files.map((file, index) => ({
              file,
              metadata: { ...directUploads[index] },
            }));
            const documentBlobsInput = merged.map((file) => ({
              ...documentBlobs,
              blobId: file.metadata.blobId,
            }));
            attachBlobsResource({ 
              variables: {
                resourceId: resource.id,
                resourceType: resource.type,
                documentBlobs: documentBlobsInput,
              },
            }).then(() => {
              resolve(merged);
            }).catch((error) => reject(error));
          }).catch((error) => reject(error));
        }).catch((error) => reject(error));
      })).catch((error) => reject(error));
    });
  }];
};

export const useSetCookie = () => {
  const [loading, setLoading] = useState(false);

  const resolver = useCallback((token) => {
    return new Promise((resolve, reject) => {
      setLoading(true);
      fetchCookie(token).then((response) => {
        setLoading(false);
        resolve(response.json());
      }).catch((error) => {
        setLoading(false);
        reject(error);
      });
    });
  }, [setLoading]);

  return [resolver, loading];
};

export const useMountEffect = (fn) => useEffect(fn, [fn]);

export const useClientRect = () => {
  const [rect, setRect] = useState(null);

  const ref = useCallback((node) => {
    if (node !== null) {
      setRect(node.getBoundingClientRect());
    }
  }, []);

  return [rect, ref];
};
