/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */

import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import tooltipIcon from '../../assets/tooltip.svg';

function FormLabel({ 
  name, 
  label,
  tooltip,
  labelClassName,
  containerClassName,
}) {
  if (label) {
    return (
      <div className={`flex flex-row space-x-1 ${containerClassName}`}>
        <label 
          className={`block field-label ${labelClassName}`} 
          htmlFor={name}
        >
          {label}
        </label>
        { tooltip ? (
          <>
            <img
              alt="tooltip"
              src={tooltipIcon}
              data-for={`${name}-tooltip`}
              data-tip={tooltip}
            />
            <ReactTooltip 
              id={`${name}-tooltip`} 
              effect="solid" 
              place="top"
              className="!text-left"
              multiline
            />
          </>
        ) : null}
      </div>
    );
  }
  return null;
}

FormLabel.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  labelClassName: PropTypes.string,
  containerClassName: PropTypes.string,
};

FormLabel.defaultProps = {
  tooltip: '',
  labelClassName: '',
  containerClassName: '',
};

export default FormLabel; 
