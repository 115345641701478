import React, { useMemo, useState, useEffect } from 'react';
import {
  Redirect,
  useLocation,
} from 'react-router-dom';

import { useQuery, useReactiveVar } from '@apollo/client';
import { get } from 'lodash';

import { useIntercom } from 'react-use-intercom';
import * as FullStory from '@fullstory/browser';

import { OrderNewContext } from '../../../../utils/contexts';
import { GET_APPROVED_OFFERS } from '../../../../graphql/queries/offers';
import { sessionVar } from '../../../../graphql/cache';
import OrderForm from '../../page_components/OrderForm';
import ApprovedPaymentPlans from '../../page_components/ApprovedPaymentPlans';
import OrderSummaryTable from '../../page_components/OrderSummaryTable';

import AutoLoad from '../../../../components/AutoLoad';

function Order() {
  const location = useLocation();

  const [orderValues, setOrderValues] = useState();
  const useQueryParams = new URLSearchParams(location.search);
  const customerNumber = useQueryParams.get('customerNumber');
  const crmOpportunityId = useQueryParams.get('crmOpportunityId');
  const crmOpportunityStage = useQueryParams.get('crmOpportunityStage');
  const crmOpportunityCloseDate = useQueryParams.get('crmOpportunityCloseDate');
  const crmOpportunityProbability = parseFloat(useQueryParams.get('crmOpportunityProbability'));
  const application = useQueryParams.get('application');
  const { update: updateIntercom } = useIntercom();

  const {
    data: customerData,
    loading: customerLoading,
  } = useQuery(GET_APPROVED_OFFERS, {
    skip: !customerNumber && !crmOpportunityId,
    variables: {
      number: customerNumber,
    },
  });

  const orderNewContext = useMemo(() => ({ // needs to be constant across rerenders
    setOrderValues, 
  }), [setOrderValues]);

  const offers = useMemo(() => get(customerData, 'company.creditAppraisal.approvedCreditTermsFormatted', []), [customerData]);

  const orderSummary = useMemo(() => {
    return (
      <OrderSummaryTable orderData={orderValues} showRemmitance={false} />
    );
  }, [orderValues]);

  const sessionData = useReactiveVar(sessionVar);

  useEffect(() => {
    const user = get(sessionData, 'session.user', null);
    const companyId = get(customerData, 'company.id', null);
    const companyUrlAdmin = !companyId ? '' : `${process.env.REACT_APP_ADMIN_URL}/admin/companies/${companyId}`;
    if (user) {
      const fullName = `${user.firstName} ${user.lastName}`;
      FullStory.identify(user.email, {
        customer: fullName,
        displayName: fullName,
        email: user.email,
      });
      updateIntercom({
        name: application === 'hubspot' ? '' : fullName,
        email: user.email,
        created_at: new Date(user.createdAt).valueOf(),
        customAttributes: {
          application: 'widget-app',
          environment: process.env.REACT_APP_NODE_ENV,
          resourceId: user.id,
          resourceType: 'User',
          companyUrlAdmin,
        },
      });
    }
  }, [sessionData, customerData, updateIntercom, application]);


  if (!customerNumber && !crmOpportunityId) return <Redirect to="/" />;

  return (
    <div className="flex flex-row divide-x divide-sf-vartana-gray-30 lg:grid lg:grid-cols-9">
      <OrderNewContext.Provider value={orderNewContext}>
        <div className="pr-8 lg:col-span-5">
          <OrderForm 
            companyNumber={customerNumber}
            crmOpportunityId={crmOpportunityId}
            crmOpportunityStage={crmOpportunityStage}
            crmOpportunityCloseDate={crmOpportunityCloseDate}
            crmOpportunityProbability={crmOpportunityProbability}
            offers={offers}
            minAllowedOrderAmount={get(customerData, 'order.company.minAllowedOrderAmount', 0)}
          />
        </div>
        <div className="pl-8 divide-y divide-sf-vartana-gray-30 lg:col-span-4">
          <AutoLoad className="mt-6" loading={customerLoading}>
            <div className="pb-8">
              <h1 className="sf-h1 text-sf-vartana-black">Approved payment plans</h1>
              <ApprovedPaymentPlans
                offers={offers}
                formattedMinAllowedOrderAmount={get(customerData, 'company.formattedMinAllowedOrderAmount', '$0')}
                pgRequired={get(customerData, 'company.creditAppraisal.pgRequired', false)}
              />
            </div>
            {orderSummary}
          </AutoLoad>
        </div>
      </OrderNewContext.Provider>
    </div>
  );
}

export default Order;
