import React, { 
  useMemo, 
  useCallback, 
  useEffect, 
  useState,
} from 'react';

import {
  Redirect,
  useLocation,
} from 'react-router-dom';
import moment from 'moment';

import { useQuery, useLazyQuery } from '@apollo/client';
import { get, capitalize } from 'lodash';

import { GET_ORDER_BY } from '../../../graphql/queries/order';
import { GET_COMPANY_BY } from '../../../graphql/queries/offers';

import { useClientRect } from '../../../utils/hooks';
import { humanDateFormat } from '../../../utils/helpers';

import AutoLoad from '../../../components/AutoLoad';

import Footer from './Footer';
import Offers from './Offers';
import Updates from './Updates';
import SadEmoji from '../../../assets/sad-emoji.svg';
import GeneratingIcon from '../../../assets/generating.svg';
import NeedInfoIcon from '../../../assets/need_info.svg';
import CanceledIcon from '../../../assets/canceled.svg';
import NewCustomerEmoji from '../../../assets/new-customer.svg';
import CustomerAdded from '../../../assets/customer-added.svg';
import Button from '../../../components/Button';

function Widget() {
  const location = useLocation();
  const useQueryParams = new URLSearchParams(location.search);
  const crmAccountId = useQueryParams.get('crmAccountId');
  const crmOpportunityId = useQueryParams.get('crmOpportunityId');
  const application = useQueryParams.get('application') || '';
  const tab = useQueryParams.get('tab');

  const [rect, footerRef] = useClientRect();

  const [customerNumber, setCustomerNumber] = useState(useQueryParams.get('customerNumber'));
  const [showCreateCustomer, setShowCreateCustomer] = useState(false);
  const proposalQueryParams = `${location.search}&customerNumber=${customerNumber}`;

  const {
    data: orderData,
    loading: orderLoading,
  } = useQuery(GET_ORDER_BY, {
    variables: {
      crmOpportunityId,
    },
  });
  const [getApprovedOffersByCustomerNumber, { loading: isOffersLoading, data: offersData }] = useLazyQuery(GET_COMPANY_BY, { 
    onCompleted: () => {
      const companyData = get(offersData, 'companyBy', null);
      if (companyData === null) {
        setShowCreateCustomer(true);
      } else {
        setCustomerNumber(get(companyData, 'number', ''));
      }
    },
  });

  const getApprovedOffers = useCallback(async () => {
    const responseData = await getApprovedOffersByCustomerNumber({
      variables: {
        number: customerNumber,
        crmAccountId,
      },
    });
    return responseData;
  }, [getApprovedOffersByCustomerNumber, customerNumber, crmAccountId]);

  const orderExists = useMemo(() => {
    return get(orderData, 'orderBy', null) !== null;
  }, [orderData]);

  useEffect(() => {
    if (!orderExists) {
      getApprovedOffers();
    }
  }, [orderData, getApprovedOffers, orderExists]);

  const orderProgressed = useMemo(() => {
    return get(orderData, 'orderBy.currentStepDetails.step', 0) >= 0
        && get(orderData, 'orderBy.currentStepDetails.progress', 0) > 0;
  }, [orderData]);

  const orderCanceled = useMemo(() => {
    return get(orderData, 'orderBy.status', '') === 'canceled';
  }, [orderData]);

  const appraisalCanceled = useMemo(() => {
    return get(offersData, 'companyBy.creditAppraisal.loanDecision', []) === 'canceled';
  }, [offersData]);

  const appraisalNotCreated = useMemo(() => {
    return get(offersData, 'companyBy.creditAppraisal', []) === null;
  }, [offersData]);

  const generatingLink = useMemo(() => {
    return get(orderData, 'orderBy.currentStepDetails.progress', 0) === 0
        && get(orderData, 'orderBy.currentStepDetails.step', 0) === 0;
  }, [orderData]);

  const isAppraisalExpired = useMemo(
    () => get(offersData, 'companyBy.creditAppraisal.expired', false),
    [offersData],
  );

  const approvedOffersAvailable = useMemo(() => {
    return !isAppraisalExpired
      && get(offersData, 'companyBy.creditAppraisal.loanDecision', []) === 'approved'
      && get(offersData, 'companyBy.creditAppraisal.approvedCreditTermsFormatted', []).length > 0;
  }, [offersData, isAppraisalExpired]);

  const applicationRequested = useMemo(() => (
    get(offersData, 'companyBy.creditAppraisal.loanDecision', '') === 'pending'
    && get(offersData, 'companyBy.creditAppraisal.applicationRequired', false)
    && ['', 'requested', 'required'].includes(get(offersData, 'companyBy.creditAppraisal.moreInfo', ''))
  ), [offersData]);

  const applicationInReview = useMemo(() => (
    get(offersData, 'companyBy.creditAppraisal.loanDecision', '') === 'pending'
    && get(offersData, 'companyBy.creditAppraisal.moreInfo', 'requested') === 'received'
  ), [offersData]);

  const appraisalDecision = useMemo(() => (
    get(offersData, 'companyBy.creditAppraisal.loanDecision', '')
  ), [offersData]);

  const updateParams = useMemo(() => {
    const params = {
      title: '',
      message: '',
      timestamp: '',
      showWizard: false,
      icon: null,
      helpUri: `/forms/help?customerNumber=${customerNumber}&help=true&application=${application}`,
    };
    params.title = get(offersData, 'companyBy.creditAppraisal.currentStepDetails.title', '');
    params.message = get(offersData, 'companyBy.creditAppraisal.currentStepDetails.message', '');
    params.timestamp = get(offersData, 'companyBy.creditAppraisal.updatedAtFormatted', '');
    if (appraisalDecision === 'declined') {
      params.timestamp = get(offersData, 'companyBy.creditAppraisal.updatedAtFormatted', '');
      params.icon = SadEmoji;
    } else if (appraisalCanceled || appraisalNotCreated) {
      params.title = 'Customer added!';
      params.timestamp = get(offersData, 'companyBy.creditAppraisal.updatedAtFormatted', '');
      params.icon = CustomerAdded;
      params.message = 'Click the button below to generate payment plans';
    } else if (applicationRequested) {
      params.icon = NeedInfoIcon;
    } else if (applicationInReview) {
      params.icon = GeneratingIcon;
      params.timestamp = get(offersData, 'companyBy.creditAppraisal.updatedAtFormatted', '');
    } else if (isAppraisalExpired) {
      params.icon = NeedInfoIcon;
      params.timestamp = get(offersData, 'companyBy.creditAppraisal.updatedAtFormatted', '');
    } else if (appraisalDecision === 'pending') {
      params.icon = GeneratingIcon;
    } else if (appraisalDecision === 'approved') {
      const searchParams = `?customerNumber=${customerNumber}&crmOpportunityId=${crmOpportunityId}&help=true&application=${application}`;
      if (orderExists) params.helpUri = `/forms/summary${searchParams}`;
      else params.helpUri = `/forms/order${searchParams}`;
    } else if (showCreateCustomer) {
      params.title = 'New customer detected!';
      params.icon = NewCustomerEmoji;
      const platform = application ? capitalize(application) : 'Salesforce';
      params.message = `Please add customer’s information in ${platform} to generate
                        payment options.`;
      params.timestamp = humanDateFormat(moment());
      params.helpUri = `/forms/customer${location.search}`;
    }
    return params;
  }, [
    application,
    applicationInReview,
    applicationRequested,
    showCreateCustomer,
    appraisalDecision,
    crmOpportunityId,
    customerNumber,
    isAppraisalExpired,
    appraisalCanceled,
    offersData,
    orderExists,
    location,
    appraisalNotCreated,
  ]);
  
  const proposalButton = useMemo(() => {
    return (
      <div className="flex flex-row justify-center items-center py-5">
        <Button
          className="border-2 border-sf-blue w-full max-w-sm rounded py-2"
          label="Generate proposal"
          type="submit"
          disabled={false}
          element={<p className="sf-button-text text-sf-blue">Create proposal</p>}
          onClick={() => window.open(`/forms/proposal${proposalQueryParams}`).focus()}
        />
      </div>
    );
  }, [proposalQueryParams]);

  const canGenerateproposal = get(offersData, 'companyBy.seller.proposalEnabled', false) && get(offersData, 'companyBy.creditAppraisal.loanDecision', '') !== 'declined';

  const updates = useMemo(() => {
    if (orderExists) {
      return (
        <div className="pt-10 px-8">
          {
            get(orderData, 'orderBy.status', '') === 'canceled' ? (
              <Updates
                title="Order canceled"
                message="Order was canceled by request"
                showWizard={false}
                icon={CanceledIcon}
                timestamp={get(orderData, 'orderBy.updatedAtFormatted', '')}
              />
            ) : (
              <Updates
                title={get(orderData, 'orderBy.currentStepDetails.title', '')}
                message={get(orderData, 'orderBy.currentStepDetails.message', '')}
                timestamp={get(orderData, 'orderBy.currentStepDetails.last_active_at', '')}
                isComplete={get(orderData, 'orderBy.currentStepDetails.completed', false)}
                progress={get(orderData, 'orderBy.currentStepDetails.progress', false)}
                step={get(orderData, 'orderBy.currentStepDetails.step', false)}
                companyNumber={customerNumber}
                crmOpportunityId={crmOpportunityId}
                showResend={!get(orderData, 'orderBy.currentStepDetails.is_active', true) && !get(orderData, 'orderBy.currentStepDetails.completed', false)}
                subtractHeight={rect?.height || 0}
                inActive={!get(orderData, 'orderBy.currentStepDetails.is_active', true) && !get(orderData, 'orderBy.currentStepDetails.completed', false)}
                showRenewTermsBtn={isAppraisalExpired}
              />
            )
          }
        </div>
      );
    }
    return (
      approvedOffersAvailable ? (
        <Offers
          offers={get(offersData, 'companyBy.creditAppraisal.approvedCreditTermsFormatted', [])}
          pgRequired={get(offersData, 'companyBy.creditAppraisal.pgRequired', false)}
          formattedMinAllowedOrderAmount={get(offersData, 'companyBy.formattedMinAllowedOrderAmount', '$0')}
          companyNumber={customerNumber}
          proposalAllowed={get(offersData, 'companyBy.seller.proposalEnabled', false)}
        />
      ) : (
        <div className="p-9">
          <Updates
            title={updateParams.title}
            message={updateParams.message}
            showWizard={updateParams.showWizard}
            icon={updateParams.icon}
            companyNumber={customerNumber}
            crmOpportunityId={crmOpportunityId}
            showSendApplication={applicationRequested}
            showCreateCustomer={showCreateCustomer}
            timestamp={updateParams.timestamp}
            showRenewTermsBtn={isAppraisalExpired || appraisalCanceled || appraisalNotCreated}
          />
          { canGenerateproposal ? proposalButton : null }
        </div>
      )
    );
  }, [
    approvedOffersAvailable, 
    updateParams, 
    offersData, 
    orderData,
    applicationRequested,
    showCreateCustomer,
    crmOpportunityId,
    customerNumber,
    isAppraisalExpired,
    appraisalCanceled,
    orderExists,
    rect,
    appraisalNotCreated,
    proposalButton,
    canGenerateproposal,
  ]);

  if (tab === 'help') return <Redirect to="/forms/help?tab=help" />;
  
  if ((!customerNumber && !crmAccountId) || !crmOpportunityId) return <Redirect to="/" />;
  
  return (
    <AutoLoad loading={orderLoading || isOffersLoading} containerClassName="flex justify-center" className="absolute text-center top-2/4 transform-gpu -translate-y-2/4">
      {updates}
      <Footer
        showTrack={orderExists && orderProgressed && !generatingLink && !orderCanceled}
        showDetail={orderExists}
        showHelp
        companyNumber={customerNumber}
        crmOpportunityId={crmOpportunityId}
        forwardedRef={footerRef}
        redirectUri={updateParams.helpUri}
      />
    </AutoLoad>
  );
}

export default Widget;
