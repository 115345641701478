import React from 'react';
import PropTypes from 'prop-types';

function Button({ 
  disabled, 
  type, 
  onClick,
  className,
  element,
}) {
  return (
    <>
      <button 
        className={`${disabled ? 'cursor-not-allowed' : ''} ${className}`}
        type={type}
        disabled={disabled}
        onClick={onClick}
      >
        {element}
      </button>
    </>
  );
}


Button.propTypes = {
  disabled: PropTypes.bool,
  type: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  element: PropTypes.elementType,
};

Button.defaultProps = {
  disabled: false,
  type: '',
  onClick: () => {},
  className: '',
  element: null,
};

export default Button;
