/* eslint-disable react/prop-types */

import React, {
  useEffect,
} from 'react';

import {
  useLocation,
} from 'react-router-dom';

import { useQuery, useReactiveVar } from '@apollo/client';
import { get } from 'lodash';

import { useIntercom } from 'react-use-intercom';
import * as FullStory from '@fullstory/browser';

import { GET_APPROVED_OFFERS } from '../../../../graphql/queries/offers';
import { sessionVar } from '../../../../graphql/cache';

import Updates from '../../Widget/Updates';
import NewCustomerEmoji from '../../../../assets/new-customer.svg';
import GeneratingIcon from '../../../../assets/generating.svg';
import SadEmoji from '../../../../assets/sad-emoji.svg';
import AutoLoad from '../../../../components/AutoLoad';
import NeedInfoIcon from '../../../../assets/need_info.svg';
import HelpNoText from '../../../../assets/help_no_text.svg';

function Help() {
  const location = useLocation();
  const useQueryParams = new URLSearchParams(location.search);
  const companyNumber = useQueryParams.get('customerNumber');
  const application = useQueryParams.get('application');
  const { update: updateIntercom } = useIntercom();

  const { 
    data: customerData,
    loading: customerLoading,
  } = useQuery(GET_APPROVED_OFFERS, {
    variables: { 
      number: companyNumber,
    },
  });

  let title = '';
  let message = '';
  let timestamp = '';
  let showWizard = false;
  let icon = null;
  const applicationRequested = (get(customerData, 'company.creditAppraisal.loanDecision', '') === 'pending' && get(customerData, 'company.creditAppraisal.applicationRequired', false) && ['', 'requested'].includes(get(customerData, 'company.creditAppraisal.moreInfo', '')));
  const applicationInReview = (get(customerData, 'company.creditAppraisal.loanDecision', '') === 'pending' && get(customerData, 'company.creditAppraisal.moreInfo', 'requested') === 'received');
  title = get(customerData, 'company.creditAppraisal.currentStepDetails.title', '');
  message = get(customerData, 'company.creditAppraisal.currentStepDetails.message', '');
  timestamp = get(customerData, 'company.creditAppraisal.updatedAtFormatted', '');
  if (!companyNumber) {
    title = 'New customer detected!';
    message = 'Add customer to Vartana to generate payment plans.';
    showWizard = false;
    icon = NewCustomerEmoji;
  } else if (get(customerData, 'company.creditAppraisal.loanDecision', '') === 'canceled' || get(customerData, 'company.creditAppraisal', []) === null) {
    title = "We're here for you!";
    timestamp = get(customerData, 'company.creditAppraisal.updatedAtFormatted', '');
    icon = HelpNoText;
    message = 'Please use the support window below to reach us directly.';
  } else if (get(customerData, 'company.creditAppraisal.loanDecision', '') === 'declined') {
    showWizard = false;
    icon = SadEmoji;
  } else if (applicationRequested) { 
    showWizard = false;
    icon = NeedInfoIcon;
  } else if (applicationInReview) {
    showWizard = false;
    icon = GeneratingIcon;
  } else {
    showWizard = false;
    icon = GeneratingIcon;
  }

  const sessionData = useReactiveVar(sessionVar);

  useEffect(() => {
    const user = get(sessionData, 'session.user', null);
    const companyId = get(customerData, 'company.id', null);
    const companyUrlAdmin = !companyId ? '' : `${process.env.REACT_APP_ADMIN_URL}/admin/companies/${companyId}`;
    if (user) {
      const fullName = `${user.firstName} ${user.lastName}`;
      FullStory.identify(user.email, {
        customer: fullName,
        displayName: fullName,
        email: user.email,
      });
      updateIntercom({
        name: application === 'hubspot' ? '' : fullName,
        email: user.email,
        created_at: new Date(user.createdAt).valueOf(),
        customAttributes: {
          application: 'widget-app',
          environment: process.env.REACT_APP_NODE_ENV,
          resourceId: user.id,
          resourceType: 'User',
          companyUrlAdmin,
        },
      });
    }
  }, [sessionData, customerData, updateIntercom, application]);

  return (
    <AutoLoad loading={customerLoading}>
      <Updates
        title={title}
        message={message}
        showWizard={showWizard}
        icon={icon}
        timestamp={timestamp}
      />
    </AutoLoad>
  );
}

export default Help;
