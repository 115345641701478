import moment from 'moment';

export async function OrderFinancingInformation(startDate, endDate, amount, companyNumber, paymentTerm, billingFrequency) {
  try {
    const starts = moment(startDate).format('YYYY-MM-DD');
    const ends = moment(endDate).format('YYYY-MM-DD');
    const response = await fetch(`${process.env.REACT_APP_API_URL}/crm/utils/financing-information?start_date=${starts}&end_date=${ends}&amount=${amount}&company_number=${companyNumber}&payment_term=${paymentTerm}&billing_frequency=${billingFrequency}`, {
      credentials: 'include',
    });

    return response.json();
  } catch (e) {
    console.log(e);
    return {};
  }
}

export async function availableTerms(startDate, isExpansion, companyNumber) {
  try {
    const starts = moment(startDate).format('YYYY-MM-DD');
    const response = await fetch(`${process.env.REACT_APP_API_URL}/crm/utils/available-terms?start_date=${starts}&is_expansion=${isExpansion}&company_number=${companyNumber}`, {
      credentials: 'include',
    });

    return response.json();
  } catch (e) {
    console.log(e);
    return {};
  }
}
