import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import copyIcon from '../../../../../../assets/copy.svg';

function TrackRecipients({ emailHistory, allowCopy }) {
  const copyUrl = (url) => {
    navigator.clipboard.writeText(url);
    setTimeout(() => {
      ReactTooltip.hide();
    }, 2000);
  };
  return (
    <div className="space-y-4 pt-8">
      <h2 className="sf-h2 inline-block text-sf-vartana-black">
        Track recipients
      </h2>
      <div>
        {emailHistory.map((email) => {
          return (
            <div>
              <div className="flex flex-row space-x-3 mt-5">
                <p className="sf-p-small-bold">{email.email}</p>
                {email.url && allowCopy ? (
                  <div>
                    <ReactTooltip id={email.email} place="top" afterShow={() => { copyUrl(email.url); }} globalEventOff="click" delayHide={1}>
                      <span>Link copied</span>
                    </ReactTooltip>
                    <div data-event="click" data-tip="Link copied" data-for={email.email} className="border-l-2 border-sf-vartana-gray-30 pl-3 flex flex-row space-x-1 cursor-pointer">
                      <img className="inline-block" src={copyIcon} alt="Copy"></img>
                      <p className="sf-p-small-bold text-sf-vartana-blue-60">Checkout link</p>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="border-l-2 border-sf-blue border-opacity-25 pl-2 mt-2">
                {email.events.map((emailEvent) => {
                  return (
                    <div className="flex flex-row space-x-2 sf-p-small">
                      <p className="text-sf-vartana-gray-70">
                        {emailEvent.message}
                      </p>
                      <p className="text-sf-vartana-gray-50">
                        {emailEvent.timestamp}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

TrackRecipients.propTypes = {
  emailHistory: PropTypes.arrayOf(PropTypes.node),
  allowCopy: PropTypes.bool,
};

TrackRecipients.defaultProps = {
  emailHistory: [],
  allowCopy: false,
};

export default TrackRecipients;
