import { gql } from '@apollo/client';

export const CREATE_DIRECT_UPLOADS = gql`
  mutation($directUploads: [DirectUploadInput!]!) {
    createDirectUploads(directUploads: $directUploads) {
      directUploads {
        url
        headers
        blobId
        signedBlobId
      }
    }
  }
`;

export const ATTACH_BLOBS_RESOURCE = gql`
  mutation(
    $resourceId: ID!
    $resourceType: String!
    $documentBlobs: [DocumentBlobInput!]!
  ) {
    attachBlobsResource(
      resourceId: $resourceId,
      resourceType: $resourceType,
      documentBlobs: $documentBlobs,
    ) {
      session {
        id
      }
      resource {
        ... on Order {
          number
        }
      }
    }
  }
`;


