import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import { GET_ORDER_BY } from '../../../../../graphql/queries/order';

import AutoLoad from '../../../../../components/AutoLoad';
import SendCheckoutLinkForm from '../../../page_components/SendCheckoutLinkForm';
import TrackRecipients from './TrackRecipients';
import Updates from '../../../Widget/Updates';

function TrackStatus({
  companyNumber,
  crmOpportunityId,
  orderNumber,
}) {
  const { 
    data: orderData,
    loading: orderLoading,
  } = useQuery(GET_ORDER_BY, {
    variables: { 
      number: orderNumber,
      crmOpportunityId,
    },
  });

  const trackRecipients = useMemo(() => {
    if (get(orderData, 'orderBy.emailHistory', []).length) {
      return <TrackRecipients emailHistory={get(orderData, 'orderBy.emailHistory', [])} allowCopy={get(orderData, 'orderBy.currentStepDetails.step', 0) <= 1} />;
    }
    return null;
  }, [orderData]);

  return (
    <div className="grid grid-cols-2 divide-x divide-sf-vartana-gray-30">
      <div className="pr-8">
        <SendCheckoutLinkForm 
          companyNumber={companyNumber} 
          crmOpportunityId={crmOpportunityId}
          orderNumber={orderNumber}
          disabled={get(orderData, 'orderBy.currentStepDetails.step', 0) > 1}
        />
      </div>
      <div className="divide-sf-vartana-gray-30 divide-y max-w-md pl-8">
        <div className="pb-8">
          <AutoLoad className="mt-6" loading={orderLoading}>
            <Updates
              title={get(orderData, 'orderBy.currentStepDetails.title', '')}
              message={get(orderData, 'orderBy.currentStepDetails.message', '')}
              timestamp={get(orderData, 'orderBy.currentStepDetails.last_active_at', '')}
              isComplete={get(orderData, 'orderBy.currentStepDetails.completed', false)}
              progress={get(orderData, 'orderBy.currentStepDetails.progress', false)}
              step={get(orderData, 'orderBy.currentStepDetails.step', false)}
              companyNumber={companyNumber}
              crmOpportunityId={crmOpportunityId}
              inActive={!get(orderData, 'orderBy.currentStepDetails.is_active', true) && !get(orderData, 'orderBy.currentStepDetails.completed', false)}
            />
          </AutoLoad>
        </div>
        {trackRecipients}
      </div>
    </div>
  );
}

TrackStatus.propTypes = {
  companyNumber: PropTypes.string.isRequired,
  crmOpportunityId: PropTypes.string,
  orderNumber: PropTypes.string,
};

TrackStatus.defaultProps = {
  crmOpportunityId: '',
  orderNumber: '',
};

export default TrackStatus;
