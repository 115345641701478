import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Route,
  Switch,
  Redirect,
  useRouteMatch,
  useLocation,
} from 'react-router-dom';

import Order from './Order';
import Customer from './Customer';
import Summary from './Summary';
import Help from './Help';
import Application from './Application';
import Proposal from './Proposal';
import vartanaLogo from '../../../assets/vartana_logo.svg';
import poweredByLogo from '../../../assets/powered_by.svg';
import crossIcon from '../../../assets/cross.svg';

import CloseConfirmation from '../../../components/Modals/CloseConfirmation';

import { CloseConfirmationModalContext } from '../../../utils/contexts';
import RequestHigherLimit from './RequestHigherLimit';

function Forms({ seller }) {
  const { search } = useLocation();
  const useQueryParams = new URLSearchParams(search);
  const referrer = useQueryParams.get('referrer');
  const application = useQueryParams.get('application');
  const isHubspot = application === 'hubspot';
  const { path: currentPath } = useRouteMatch();

  const logo = useMemo(() => {
    if (referrer === 'partner') {
      return {
        source: seller.logoUrl,
        altText: seller.name,
      };
    }
    return {
      source: vartanaLogo,
      altText: 'vartana',
    };
  }, [seller, referrer]);

  const [showClosePopup, setshowClosePopup] = useState(false);
  const [needCloseConfirmation, setNeedCloseConfirmation] = useState(false);

  const closeTab = useCallback(() => {
    if (needCloseConfirmation) {
      setshowClosePopup(true);
    } else {
      window.close();
    }
  }, [needCloseConfirmation]);

  const cancelFooter = useMemo(() => {
    if (referrer === 'partner' || isHubspot) return null;
    return (
      <div className="mt-8 border-t border-sf-vartana-gray-30">
        <button className="mt-6 block mx-auto pointer-cursor" onClick={closeTab}>
          <p className="sf-text-link text-sf-blue">Close window</p>
        </button>
      </div>
    );
  }, [closeTab, referrer, isHubspot]);

  const poweredBy = useMemo(() => {
    if (referrer === 'partner') {
      return (
        <div className="flex flex-row-reverse mt-8 border-t border-sf-vartana-gray-30">
          <img className="mt-8" alt="powered-by-vartana" src={poweredByLogo} />
        </div>
      );
    }
    return null;
  }, [referrer]);

  const closeConfirmationModalContext = useMemo(() => ({ // needs to be constant across rerenders
    setNeedCloseConfirmation,
    closeTab,
  }), [setNeedCloseConfirmation, closeTab]);

  const closeButton = useMemo(() => {
    if (isHubspot) return null;
    return (
      <button className="block ml-auto p-4 cursor-pointer" onClick={closeTab}>
        <img alt="close" src={crossIcon} />
      </button>
    );
  }, [isHubspot, closeTab]);

  const styles = useMemo(() => {
    const style = {
      hubspot: {
        tab: 'flex flex-row justify-center items-center',
        outer: 'bg-white m-10 p-2 w-4/5 max-w-5xl',
        inner: '',
      },
      default: {
        tab: 'pt-16 flex flex-row justify-center items-center',
        outer: 'bg-white border border-solid rounded-lg pb-9 card-shadow lg:max-card-width',
        inner: 'px-9',
      },
    };
    return isHubspot ? style.hubspot : style.default;
  }, [isHubspot]);

  return (
    <>
      <CloseConfirmation 
        open={showClosePopup} 
        onClose={() => setshowClosePopup(false)} 
        onConfirm={() => window.close()}
      />
      <CloseConfirmationModalContext.Provider value={closeConfirmationModalContext}>
        <div className={styles.tab}>
          <div className={styles.outer}>
            {closeButton}
            <div className={styles.inner}>
              <img alt={logo.altText} src={logo.source} className="mb-9 h-7" />
              <Switch>
                <Route path={`${currentPath}/order`} component={Order} />
                <Route path={`${currentPath}/customer`} component={Customer} />
                <Route path={`${currentPath}/summary`} component={Summary} />
                <Route path={`${currentPath}/help`} component={Help} />
                <Route path={`${currentPath}/application`} component={Application} />
                <Route path={`${currentPath}/request-higher-limit`} component={RequestHigherLimit} />
                <Route path={`${currentPath}/proposal`} component={Proposal} />
                <Redirect to="/" />
              </Switch>
              {poweredBy}
            </div>
            {cancelFooter}
          </div>
        </div>
      </CloseConfirmationModalContext.Provider>
    </>
  );
}

Forms.propTypes = {
  seller: PropTypes.shape({
    name: PropTypes.string,
    logoUrl: PropTypes.string,
  }),
};

Forms.defaultProps = {
  seller: {
    name: '',
    logoUrl: '',
  },
};

export default Forms;
