import { getAPIURL } from '../utils/helpers';

export const fetchCookie = (token) => {
  return fetch(`${getAPIURL()}/set-cookie`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ 
      token,
      app: 'widget-app',
    }),
    credentials: 'include',
  });
};
