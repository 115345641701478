import { gql } from '@apollo/client';

export const CREATE_CREDIT_APPRAISAL_CHANGE_REQUEST = gql`
  mutation(
    $companyNumber: String!,
    $firstName: String,
    $lastName: String,
    $email: String,
    $phone: String,
    $companyMessage: String,
    $increaseThresholdAmount: Boolean!,
    $increaseTerm: Boolean!,
    $message: String!,
    $amount: Float,
    $term: Float,
    $billingFrequencies: [String!],
    $paymentTerms: [Int!]
  ) {
      createCreditAppraisalChangeRequest(
        companyNumber: $companyNumber
        firstName: $firstName
        lastName: $lastName
        email: $email
        phone: $phone
        companyMessage: $companyMessage
        increaseThresholdAmount: $increaseThresholdAmount
        increaseTerm: $increaseTerm
        message: $message
        amount: $amount
        term: $term
        billingFrequencies: $billingFrequencies
        paymentTerms: $paymentTerms
      ) {
          id
          number
      }
  }
`;
